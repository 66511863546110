import React, { useState, useEffect } from "react";

import classNames from "classnames/bind";
import styles from "page/Application/Cart/Cart.module.scss";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import { useRecoilValue, useRecoilState } from "recoil";
import { cartItemCountSelector, cartItemsState } from "recoil/atom/cart";
import LocationAddress from "hook/useGetAddressLocal";
import TipSelector from "components/TipSelector";
import OrderSummary from "components/OrderSummary";
import { Link, useNavigate } from "react-router-dom";
import { HOMEPAGE } from "constant/appPath";

const cx = classNames.bind(styles);

function Cart() {
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);
  const [tipPercent, setTipPercent] = useState(5);
  const address = LocationAddress();
  const navigate = useNavigate();

  // Hàm xử lý xóa sản phẩm khỏi giỏ hàng
  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter(
      (item) => item.product.id !== productId
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  // Tính tổng số lượng sản phẩm trong giỏ hàng
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Hàm tính tổng tiền giỏ hàng
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.totalPrice; // Tính tổng tiền của từng sản phẩm và cộng vào total
    });
    return total;
  };
  const cartItemCount = useRecoilValue(cartItemCountSelector);



  // Hàm để cập nhật phần trăm tip khi người dùng chọn
  const handleTipChange = (percent) => {
    setTipPercent(percent);
  };

  // Hàm tính tiền tip dựa trên phần trăm tip
  const calculateTip = () => {
    return (calculateTotal() * tipPercent) / 100;
  };

  const handleContinueToPayment = () => {
    // Truyền thông tin thanh toán sang trang Checkout
    navigate("/checkout", {
      state: {
        subtotal: calculateTotal(),
        tipAmount: calculateTip(),
        tipPercent: tipPercent
      }
    });
  };

  return (
    <div className={cx("index-container")}>
      <Container className={cx("body-container")}>
        <Row className={cx("row")}>
          <Col className={cx("title-cart-page")}>Your cart</Col>
        </Row>
        <Row className={cx("row")}>
          <Col sm={8}>
            {
              cartItems.length === 0
                ?
                (<>
                  <Link to={HOMEPAGE} className={cx("btn-order-now")}>Order now</Link>
                </>)
                :
                (<>
                  <h2 className={cx("title-cart")}>
                    YOUR ORDER ({totalItems} ITEMS)
                  </h2>
                </>)
            }

            {cartItems.map((item) => (
              <Container className={cx("cart-item")}>
                <Row>
                  <Col md={4} className={cx("cart-image-product")}>
                    <Card.Img
                      variant="top"
                      src={item.product.thumbnail}
                      alt={item.product.product_name}
                      className={cx("cart-image-product-img")}
                    />
                  </Col>
                  <Col md={5} className={cx("cart-item-info")}>
                    <Card.Body>
                      <Card.Title className={cx("cart-item-text-name")}>
                        {item.product.product_name}
                      </Card.Title>
                      {item.attribute.map((attributeItem) => (
                        <Card.Text className={cx("cart-item-text")}>
                          {attributeItem.attribute_name}: {JSON.parse(attributeItem.item).item_name}
                        </Card.Text>
                      ))}
                    </Card.Body>
                  </Col>
                  <Col md={3} className={cx("cart-item-info")}>
                    <Card.Body>
                      <Card.Text className={cx("cart-item-text")}>
                        ${item.totalPrice}
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className={cx("cart-item-text-quantity")}>
                    Quantity: {item.quantity}
                  </Col>
                  <Col md={6} className={cx("cart-item-text-quantity")}>
                    <div
                      onClick={() => removeFromCart(item.product.id)}
                      className={cx("cart-item-icon-delete")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M21 5h-6a3 3 0 0 0-3-3 3 3 0 0 0-3 3H3v2h2v11.75C5 20.54 6.66 22 8.7 22h6.6c2.04 0 3.7-1.46 3.7-3.25V7h2V5Zm-9-1a1 1 0 0 1 1 1h-2a1 1 0 0 1 1-1Zm5 14.75c0 .68-.78 1.25-1.7 1.25H8.7c-.92 0-1.7-.57-1.7-1.25V7h10v11.75ZM15 9h-2v9h2V9ZM9 9h2v9H9V9Z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </Col>
                </Row>
                <Row className={cx("cart-hr")}></Row>
              </Container>
            ))}
          </Col>
          <Col sm={4}>
            <h2 className={cx("title-cart")}>HOW TO GET IT</h2>
            <Row className={cx("row-address")}>
              <Col>
                <div className={cx("row-address-item")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    version="1.1"
                    class="fulfillment-location__icon"
                  >
                    <path
                      d="M 7.355 0.033 C 6.013 0.184, 4.763 0.789, 3.797 1.755 C 2.850 2.702, 2.243 3.921, 2.058 5.251 C 1.998 5.682, 1.998 6.510, 2.057 6.993 C 2.338 9.263, 3.669 11.779, 5.940 14.334 C 6.489 14.952, 7.347 15.791, 7.536 15.895 C 7.687 15.978, 7.723 15.985, 8 15.985 C 8.412 15.985, 8.501 15.935, 9.058 15.389 C 11.039 13.451, 12.631 11.164, 13.398 9.156 C 14.221 7, 14.213 5.075, 13.374 3.377 C 12.729 2.073, 11.652 1.041, 10.351 0.483 C 9.412 0.080, 8.352 -0.079, 7.355 0.033 M 7.385 2.056 C 6.558 2.197, 5.872 2.543, 5.276 3.117 C 3.417 4.910, 3.619 7.693, 5.848 10.972 C 6.365 11.733, 6.896 12.400, 7.600 13.173 L 7.992 13.604 8.112 13.488 C 8.468 13.147, 9.255 12.219, 9.726 11.587 C 11.087 9.758, 11.847 8.045, 11.983 6.505 C 12.145 4.659, 11.142 2.973, 9.478 2.295 C 8.841 2.035, 8.040 1.944, 7.385 2.056 M 7.640 4.035 C 6.691 4.218, 6.005 5.045, 6.005 6.004 C 6.005 6.826, 6.529 7.588, 7.295 7.875 C 8.123 8.187, 9.047 7.924, 9.590 7.223 C 9.869 6.862, 9.995 6.483, 9.995 6.004 C 9.995 5.525, 9.869 5.145, 9.590 4.785 C 9.131 4.192, 8.360 3.895, 7.640 4.035"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                  Pickup: {address}
                </div>
                <div className={cx("row-address-item")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    version="1.1"
                  >
                    <path
                      d="M 7.355 0.020 C 5.725 0.179, 4.332 0.727, 3.062 1.705 C 2.733 1.959, 1.959 2.733, 1.705 3.062 C 0.629 4.459, 0.064 6.017, 0.009 7.747 C -0.034 9.104, 0.227 10.319, 0.826 11.542 C 2.027 13.996, 4.448 15.679, 7.196 15.972 C 7.635 16.018, 8.793 15.992, 9.198 15.927 C 10.944 15.644, 12.432 14.888, 13.660 13.660 C 14.462 12.857, 15.050 11.963, 15.460 10.924 C 15.718 10.268, 15.831 9.790, 16 8.637 C 16.039 8.376, 16.039 7.514, 16 7.348 C 15.984 7.277, 15.950 7.057, 15.924 6.859 C 15.830 6.131, 15.569 5.267, 15.231 4.564 C 14.004 2.007, 11.580 0.312, 8.753 0.032 C 8.453 0.003, 7.607 -0.005, 7.355 0.020 M 7.227 2.057 C 5.902 2.238, 4.699 2.831, 3.765 3.765 C 2.637 4.893, 2.011 6.404, 2.011 7.999 C 2.011 10.497, 3.535 12.709, 5.877 13.613 C 6.971 14.035, 8.302 14.113, 9.456 13.824 C 11.174 13.394, 12.617 12.226, 13.388 10.642 C 13.807 9.781, 13.993 8.971, 13.993 8 C 13.993 7.425, 13.942 7.018, 13.805 6.485 C 13.256 4.352, 11.526 2.668, 9.366 2.163 C 8.735 2.016, 7.853 1.972, 7.227 2.057 M 7.786 4.019 C 7.446 4.107, 7.152 4.383, 7.053 4.710 C 6.994 4.905, 6.992 4.972, 7.001 6.576 L 7.009 8.240 7.093 8.420 C 7.165 8.574, 7.303 8.726, 8.039 9.460 C 9.052 10.471, 9.079 10.491, 9.501 10.491 C 9.736 10.492, 9.793 10.481, 9.940 10.408 C 10.138 10.311, 10.335 10.110, 10.427 9.911 C 10.478 9.801, 10.491 9.714, 10.491 9.501 C 10.491 9.093, 10.451 9.033, 9.664 8.240 L 9.008 7.580 8.999 6.154 L 8.991 4.728 8.888 4.538 C 8.716 4.221, 8.423 4.032, 8.068 4.008 C 7.965 4.001, 7.838 4.006, 7.786 4.019 M 0.013 8 C 0.013 8.314, 0.017 8.438, 0.022 8.276 C 0.028 8.114, 0.028 7.858, 0.022 7.706 C 0.017 7.554, 0.012 7.686, 0.013 8"
                      fill-rule="evenodd"
                    ></path>
                  </svg>{" "}
                  Tomorrow at 8:25 AM
                </div>
                <hr />
                <div className={cx("row-add-tip")}>
                  <TipSelector
                    selectedTip={tipPercent}
                    onTipChange={handleTipChange}
                  />
                </div>
                <hr />
                <div className={cx("row-add-tip")}>
                  <OrderSummary
                    subtotal={calculateTotal()}
                    tipAmount={calculateTip()}
                    tipPercent={tipPercent}
                  />
                </div>
              </Col>
            </Row>
            <Row className={cx("row-payment")}>
              <Col>
                <Button className={cx("btn-paymen", { disabled: cartItems.length === 0 })} onClick={handleContinueToPayment}>Continue to payment</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cart;
