import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { getAttributeByProductId } from "api/app/product.api";

const ProductDetailModal = ({
  product,
  addToCart,
  showModal,
  handleCloseModal,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0); // State để lưu tổng tiền
  const [initialProductPrice, setInitialProductPrice] = useState(0); // Giá sản phẩm ban đầu

  const handleQuantityDecrease = () => {
    setQuantity(quantity > 1 ? quantity - 1 : 1);
    calculateTotalPrice(); // Tính tổng tiền khi giảm số lượng
  };

  const handleQuantityIncrease = () => {
    setQuantity(quantity + 1);
    calculateTotalPrice(); // Tính tổng tiền khi tăng số lượng
  };

  const handleAttributeChange = (attribute, item, checkFlg) => {

    // Tạo một bản sao của mảng selectedAttributes
    let updatedSelectedAttributes = [...selectedAttributes];

    // Tìm kiếm xem phần tử có attribute_id tương ứng đã tồn tại trong mảng selectedAttributes chưa
    const existingIndex = updatedSelectedAttributes.findIndex(
      (selectedItem) => selectedItem.attribute_id === attribute.attribute_id
    );

    // Nếu checkFlg là true, cập nhật hoặc thêm mới phần tử
    if (checkFlg) {
      if (existingIndex !== -1) {
        updatedSelectedAttributes[existingIndex] = {
          attribute_id: attribute.attribute_id,
          attribute_name: attribute.attribute_name,
          item,
        };
      } else {
        updatedSelectedAttributes.push({
          attribute_id: attribute.attribute_id,
          attribute_name: attribute.attribute_name,
          item,
        });
      }
    } else { // Nếu checkFlg là false, xóa phần tử khỏi mảng
      if (existingIndex !== -1) {
        updatedSelectedAttributes = updatedSelectedAttributes.filter(
          (selectedItem) => selectedItem.attribute_id !== attribute.attribute_id
        );
      }
    }

    // Cập nhật state với mảng mới
    setSelectedAttributes(updatedSelectedAttributes);

    calculateTotalPrice(); // Gọi hàm tính tổng tiền khi chọn thuộc tính
  };

  // Hàm tính tổng tiền
  const calculateTotalPrice = () => {
    // Tính giá tiền của sản phẩm dựa trên số lượng
    let productPrice = initialProductPrice * quantity;

    // Tính giá tiền của các thuộc tính đã chọn
    let attributePrice = selectedAttributes.reduce((acc, selectedItem) => {
      // Lấy giá trị của item từ phần tử được chọn
      const item = JSON.parse(selectedItem.item);
      // Lấy giá từ item và cộng vào tổng
      return acc + parseFloat(item.price);
    }, 0);

    // Tổng giá tiền bằng tổng giá của sản phẩm và giá của các thuộc tính đã chọn
    setTotalPrice(productPrice + attributePrice);
  };

  const handleOrder = () => {
    addToCart(product, quantity, selectedAttributes, totalPrice);
    handleCloseModal();
  };

  const fetchData = async () => {
    const response = await getAttributeByProductId(product.id);
    setAttributes(response);
  };

  useEffect(() => {
    if (isOpen && product) {
      fetchData();
    }
  }, [isOpen, product]);

  useEffect(() => {
    if (showModal) {
      setIsOpen(true);
      // Đặt giá sản phẩm ban đầu khi modal mở
      setInitialProductPrice(product.cost_price);
      // Tính tổng giá trị ban đầu là giá của sản phẩm
      setTotalPrice(product.cost_price);
    }
  }, [showModal]);

  useEffect(() => {
    calculateTotalPrice(); // Gọi hàm tính tổng tiền khi số lượng thay đổi
  }, [quantity, selectedAttributes]);

  const handleModalClose = () => {
    setIsOpen(false);
    handleCloseModal();
  };

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{product && product.product_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {product && (
          <>
            <img
              src={product.thumbnail}
              alt={product.product_name}
              style={{ width: "100%" }}
            />
            <p>{product.information}</p>
            <p>Price: ${product.cost_price}</p>
            <Form.Group controlId="quantity">
              <Form.Label>Quantity: </Form.Label>
              <Button
                variant="outline-secondary"
                onClick={handleQuantityDecrease}
              >
                -
              </Button>{" "}
              {quantity}{" "}
              <Button
                variant="outline-secondary"
                onClick={handleQuantityIncrease}
              >
                +
              </Button>
            </Form.Group>
            <hr
              style={{
                color: "brown", // Màu của đường ngang
                backgroundColor: "brown", // Màu nền của đường ngang
                height: 5, // Chiều cao của đường ngang
              }}
            />
            {attributes &&
              attributes.length > 0 &&
              attributes.map((attribute, index) => (
                <Form.Group key={index} controlId={index}>
                  <Form.Label>{attribute.attribute_name}: </Form.Label>
                  {attribute.option_display === 1 ? (
                    <Form.Select
                      onChange={(e) =>
                        handleAttributeChange(attribute, e.target.value, true)
                      }
                    >
                      <option key={0} value={null}>
                        select item
                      </option>
                      {attribute &&
                        attribute.items &&
                        attribute.items.length > 0 &&
                        attribute.items.map((option, index) => (
                          <option key={index} value={JSON.stringify(option)}>
                            {option.item_name} - ${option.price}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    attribute.option_display === 2 && (
                      <>
                        {attribute &&
                          attribute.items &&
                          attribute.items.length > 0 &&
                          attribute.items.map((option, index) => (
                            <Form.Check // prettier-ignore
                              type="checkbox"
                              id={option.item_name}
                              label={`${option.item_name} - $${option.price}`}
                              onChange={(e) =>
                                handleAttributeChange(
                                  attribute,
                                  JSON.stringify(option),
                                  e.target.checked
                                    ? true
                                    : false
                                )
                              }
                            />
                          ))}
                      </>
                    )
                  )}
                  <hr
                    style={{
                      color: "brown", // Màu của đường ngang
                      backgroundColor: "brown", // Màu nền của đường ngang
                      height: 5, // Chiều cao của đường ngang
                    }}
                  />
                </Form.Group>
              ))}
          </>
        )}
        <Form.Group controlId="estimateOrder">
          <Form.Label>Estimated Order Total: ${totalPrice}</Form.Label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleOrder}>
          Add to order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailModal;
