import React from "react";
import { Outlet } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "page/Auth/Auth.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

const Auth = () => {
  return (
    <div className={cx("auth-main-wrapper")}>
      <Container fluid>
        <Row>
          <Col sm={8}>
            <div className={cx("auth-header-container")}>
              <div className={cx("bg-right-abs")}>
                <h1 className={cx("auth-text-webcome")}><Link to="/">Go back to Home</Link></h1>
              </div>
              <div className={cx("bg-right-arrow")}></div>
            </div>
          </Col>
          <Col sm={4}><Outlet /></Col>
        </Row>
      </Container>

    </div>
  );
};

export default Auth;