import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import classNames from "classnames/bind";
import styles from "layouts/components/Footer/Footer.module.scss";

const cx = classNames.bind(styles);

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000);
          e.target.reset();
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000);
        }
      );
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        name="email"
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        required
        className={cx("footer-input-email")}
      />
      <button
        type="submit"
        disabled={isSubmitting}
        className={cx("footer-button-sign-up")}
      >
        {isSubmitting ? "Sending..." : "Sign Up"}
      </button>

      {stateMessage && (
        <p className={stateMessage ? "success" : "error"}>{stateMessage}</p>
      )}
    </form>
  );
};

export default SignUpForm;
