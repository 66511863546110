import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { getListAllProductByCategory } from "api/app/product.api";
import classNames from "classnames/bind";
import styles from "page/Application/Home/Home.module.scss";
import ProductDetailModal from "components/Modal/ProductDetail/ProductDetailModal";
import { useRecoilState } from "recoil";
import { cartItemsState } from "recoil/atom/cart";

const cx = classNames.bind(styles);

const ListProductByCategory = ({ category }) => {
  const [listProduct, setListProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartItems, setCartItems] = useRecoilState(cartItemsState); // Lấy và cập nhật trạng thái giỏ hàng từ Recoil

  // Hàm thêm sản phẩm vào giỏ hàng
  const addToCart = (product, quantity, selectedAttributes, totalPrice) => {
    // Kiểm tra xem sản phẩm đã có trong giỏ hàng chưa
    const existingItem = cartItems.find(
      (item) => item.product.id === product.id
    );

    if (existingItem) {
      // Tạo một bản sao của existingItem để tránh lỗi chỉ đọc
      const newItem = { ...existingItem };
      // Tăng số lượng lên 1
      newItem.quantity += 1;
      // Tạo một mảng mới với các phần tử cũ cùng với newItem
      const updatedCartItems = cartItems.map((item) =>
        item.product.id === product.id ? newItem : item
      );
      // Cập nhật trạng thái Recoil
      setCartItems(updatedCartItems);
    } else {
      // Nếu sản phẩm chưa có trong giỏ hàng, thêm sản phẩm mới vào giỏ hàng với số lượng là 1
      const newItem = {
        product: product,
        quantity: quantity,
        attribute: selectedAttributes,
        totalPrice: totalPrice,
      };
      // Cập nhật trạng thái Recoil
      setCartItems([...cartItems, newItem]);
    }

    // Lưu thông tin giỏ hàng vào local storage
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };

  const fetchData = async () => {
    const response = await getListAllProductByCategory(category.id);
    setListProduct(response.products);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (listProduct.length === 0) {
    return <></>;
  }

  // Hàm mở modal và cài đặt sản phẩm được chọn
  const handleShowModal = (product) => {
    setSelectedProduct(product);
  };

  // Hàm đóng modal
  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className={cx("product-list")} id={category.id}>
      <h5 className={cx("title-category")}>{category.name}</h5>
      <Row className={cx("product-row")}>
        {listProduct.map((product) => (
          <Col key={product.id} lg={6} md={12} className={cx("product-col")}>
            <Card
              className="mb-4"
              onClick={() => handleShowModal(product)}
              style={{ cursor: "pointer" }}
            >
              <Row>
                <Col md={8}>
                  <Card.Body>
                    <Card.Title className={cx("title-product")}>
                      {product.product_name}
                    </Card.Title>
                    <Card.Text className={cx("content-product")}>
                      {product.information}
                    </Card.Text>
                    <Card.Text className="price">
                      ${product.cost_price}
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col md={4} className={cx("image-product")}>
                  <Card.Img
                    variant="top"
                    src={product.thumbnail}
                    alt={product.product_name}
                    className={cx("image-product-img")}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Hiển thị modal chi tiết sản phẩm */}
      <ProductDetailModal
        product={selectedProduct}
        showModal={!!selectedProduct}
        handleCloseModal={handleCloseModal}
        addToCart={addToCart}
      />
    </div>
  );
};

export default ListProductByCategory;
