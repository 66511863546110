import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { addNewUser, getUserById, updateUserById } from "api/admin/user.api";
import { ADMIN_PATH, ADMIN_USER_PATH } from "constant/authPath";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

const AdminUserNew = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nickname, setNickname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (isEditMode) {
            // Nếu ở chế độ chỉnh sửa, lấy thông tin của mục cần chỉnh sửa và điền vào các trường
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await getUserById(id);
            setUsername(response.username);
            setEmail(response.email);
            setPassword(response.password);
            setRole(response.role);
            setFirstName(response.first_name);
            setLastName(response.last_name);
            setNickname(response.nickname);
            setPhoneNumber(response.phone_number);
            setAddress(response.address);

        } catch (error) {
            toast.error('Thực hiện xóa thất bại và có lỗi: '.error.message);
            console.error("Lỗi khi lấy dữ liệu mục chỉnh sửa:", error);
        }
    };

    const { id } = useParams(); // Lấy ID từ URL
    const isEditMode = !!id;
    console.log("Đang ở chế độ chỉnh sửa ID: ", id);



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                const response = await updateUserById(id, username, email, password, role, firstName, lastName, nickname, phoneNumber, address);
                window.location.href = ADMIN_PATH + "/" + ADMIN_USER_PATH;
            } else {
                const response = await addNewUser(username, email, password, role, firstName, lastName, nickname, phoneNumber, address);
                window.location.href = ADMIN_PATH + "/" + ADMIN_USER_PATH;
            }

        } catch (error) {
            toast.error('Thao tác thất bại và có lỗi: ' + error.response);
        }

    };
    return (
        <Container fluid>
            <Row className="flex-">
                <Col sm={6}>
                    <h2>{isEditMode ? "Chỉnh sửa người dùng" : "Thêm mới người dùng"}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={2}><Form.Label name="username">Tên đăng nhập:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="username"
                                    label="Tên đăng nhập"
                                    size="50"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="email">Email:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="email"
                                    label="Email"
                                    size="50"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="password">Mật khẩu:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="password"
                                    label="Mật khẩu"
                                    type="password"
                                    size="50"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="role">Vai trò:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="role"
                                    label="Vai trò"
                                    as="select"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option value="">Chọn vai trò</option>
                                    <option value="admin">Quản trị viên</option>
                                    <option value="customer">Khách hàng</option>
                                    <option value="collaborator">Cộng tác viên</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="firstName">Họ:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="firstName"
                                    label="Họ"
                                    size="50"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="lastName">Tên:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="lastName"
                                    label="Tên"
                                    size="50"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="nickname" size="50">Nickname:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="nickname"
                                    label="Nickname"
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="phoneNumber">Số điện thoại:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="phoneNumber"
                                    label="Số điện thoại"
                                    size="50"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        <Row>
                            <Col sm={2}><Form.Label name="address">Địa chỉ:</Form.Label></Col>
                            <Col sm={10}>
                                <Form.Control
                                    name="address"
                                    label="Địa chỉ"
                                    size="100"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: "5px" }}></div>
                        {errorMessage && (
                            <Row>
                                <Col sm={2}></Col>
                                <Col sm={10} style={{ color: "red" }}>
                                    {errorMessage}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={10}>
                                <Button variant="primary" type="submit">
                                    {isEditMode ? "Cập nhật" : "Thêm mới"}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </Col>
                <Col sm={4}>Thông tin khác:</Col>
            </Row>

        </Container>
    );
}

export default AdminUserNew;