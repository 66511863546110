import React, { useState, useEffect } from "react";

import classNames from "classnames/bind";
import styles from "page/Application/Cart/Cart.module.scss";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { cartItemsState } from "recoil/atom/cart";
import LocationAddress from "hook/useGetAddressLocal";
import TipSelector from "components/TipSelector";
import OrderSummary from "components/OrderSummary";
import { useLocation } from "react-router-dom";
import { getPaymentMethods } from "api/app/payment.api";
import PaymentTabs from "components/PaymentTabs";
import PaymentDetails from "components/PaymentDetails";
import { placeOrder } from "api/app/order.api";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

const Checkout = () => {
  const location = useLocation();
  const { subtotal, tipPercent } = location.state;
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);
  const [tipPercentCheckout, setTipPercentCheckout] = useState(tipPercent);
  const [address, setAddress] = useState(LocationAddress()); // Trạng thái lưu trữ địa chỉ giao hàng
  const [phone, setPhone] = useState(""); // Trạng thái lưu trữ số điện thoại liên hệ
  const [note, setNote] = useState(""); // Trạng thái lưu trữ ghi chú
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [errors, setErrors] = useState([]); // Trạng thái lưu trữ danh sách lỗi

  const navigate = useNavigate();

  // Hàm để cập nhật phần trăm tip khi người dùng chọn
  const handleTipChange = (percent) => {
    setTipPercentCheckout(percent);
  };

  // Hàm tính tiền tip dựa trên phần trăm tip
  const calculateTip = () => {
    return (subtotal * tipPercentCheckout) / 100;
  };

  // Hàm tính tổng tiền đơn hàng
  const calculateOrderTotal = () => {
    return subtotal + calculateTip();
  };

  const fetchPaymentMethods = async () => {
    const methods = await getPaymentMethods();
    setPaymentMethods(methods);
  };

  const handleMethodSelect = (methodId) => {
    setSelectedMethod(methodId);
  };

  // Hàm kiểm tra định dạng số điện thoại
  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^\d{10,}$/; // Chuỗi phù hợp với số điện thoại: ít nhất 10 chữ số
    return phonePattern.test(phoneNumber);
  };

  // Hàm xử lý validate
  const validateInputs = () => {
    const errors = [];

    if (!selectedMethod) {
      errors.push("Please select a payment method.");
    }
    if (!validatePhoneNumber(phone)) {
      errors.push("Please enter a valid phone number.");
    }
    if (!address.trim() || !note.trim()) {
      errors.push("Please fill in all shipment details.");
    }

    setErrors(errors);
    return errors.length === 0;
  };

  // Hàm xử lý khi người dùng click vào nút "Place order"
  const handlePlaceOrder = async () => {
    // Validate trước khi gửi đơn hàng
    if (!validateInputs()) {
      return;
    }
    // Gửi đơn hàng lên server và xử lý thanh toán
    try {
      const payloadData = {
        order_status: "processing",
        shipping_address: address,
        shipping_method: null,
        phipping_phone: phone,
        phipping_note: note,
        shipping_cost: 0,
        payment_method_id: selectedMethod,
        payment_status: 1,
        total_amount: parseFloat(calculateOrderTotal()),
        order_details: cartItems,
      };
      const orderResponse = await placeOrder(payloadData);

      // Kiểm tra nếu phản hồi từ API là thành công
      if (orderResponse.status_code === "OK") {
        console.log("check orderResponse.status: ", orderResponse.status);
        // Xóa thông tin giỏ hàng khỏi localStorage
        localStorage.removeItem("cartItems");
        // Xóa thông tin giỏ hàng khỏi state
        setCartItems([]);
        // Di chuyển về màn hình Order thành công
        navigate("/order-success", {
          state: {
            order: orderResponse.order,
            orderDetails: orderResponse.order_details,
            paymentMethod: selectedMethod,
          },
        });
      }
      // Sau khi đặt hàng thành công, có thể cập nhật state hoặc chuyển hướng trang
    } catch (error) {
      // Xử lý khi đặt hàng thất bại, chẳng hạn thông báo lỗi cho người dùng
      alert("Failed to place order. Please try again later.");
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <div className={cx("index-container")}>
      <Container className={cx("body-container")}>
        <Row className={cx("row")}>
          <Col className={cx("title-cart-page")}>Checkout</Col>
        </Row>
        <Row className={cx("row")}>
          <Col sm={8}>
            <h2 className={cx("title-cart")}>EXPRESS CHECKOUT</h2>
            <PaymentTabs
              paymentMethods={paymentMethods}
              onSelect={handleMethodSelect}
            />
            {selectedMethod && (
              <PaymentDetails
                method={paymentMethods.find(
                  (method) => method.id === selectedMethod
                )}
              />
            )}
          </Col>
          <Col sm={4}>
            <h2 className={cx("title-cart")}>HOW TO GET IT</h2>
            <Row className={cx("row-address")}>
              <Col>
                <div className={cx("row-address-item")}>
                  <h2 className={cx("title-cart")}>Shipment Details</h2>
                  <Row className={cx("row-shipment-detail")}>
                    <Col lg={3} md={12}>
                      Shipping address:
                    </Col>
                    <Col lg={9} md={12}>
                      <textarea
                        rows={2}
                        style={{ width: "100%" }}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className={cx("checkout-input-text")}
                      />
                    </Col>
                  </Row>
                  <Row className={cx("row-shipment-detail")}>
                    <Col lg={3} md={12}>
                      Phone:
                    </Col>
                    <Col lg={9} md={12}>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className={cx("checkout-input-text")}
                      />
                    </Col>
                  </Row>
                  <Row className={cx("row-shipment-detail")}>
                    <Col lg={3} md={12}>
                      Note:
                    </Col>
                    <Col lg={9} md={12}>
                      <textarea
                        style={{ width: "100%" }}
                        rows={4}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        className={cx("checkout-input-text")}
                      />
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className={cx("row-address-item")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    version="1.1"
                  >
                    <path
                      d="M 7.355 0.020 C 5.725 0.179, 4.332 0.727, 3.062 1.705 C 2.733 1.959, 1.959 2.733, 1.705 3.062 C 0.629 4.459, 0.064 6.017, 0.009 7.747 C -0.034 9.104, 0.227 10.319, 0.826 11.542 C 2.027 13.996, 4.448 15.679, 7.196 15.972 C 7.635 16.018, 8.793 15.992, 9.198 15.927 C 10.944 15.644, 12.432 14.888, 13.660 13.660 C 14.462 12.857, 15.050 11.963, 15.460 10.924 C 15.718 10.268, 15.831 9.790, 16 8.637 C 16.039 8.376, 16.039 7.514, 16 7.348 C 15.984 7.277, 15.950 7.057, 15.924 6.859 C 15.830 6.131, 15.569 5.267, 15.231 4.564 C 14.004 2.007, 11.580 0.312, 8.753 0.032 C 8.453 0.003, 7.607 -0.005, 7.355 0.020 M 7.227 2.057 C 5.902 2.238, 4.699 2.831, 3.765 3.765 C 2.637 4.893, 2.011 6.404, 2.011 7.999 C 2.011 10.497, 3.535 12.709, 5.877 13.613 C 6.971 14.035, 8.302 14.113, 9.456 13.824 C 11.174 13.394, 12.617 12.226, 13.388 10.642 C 13.807 9.781, 13.993 8.971, 13.993 8 C 13.993 7.425, 13.942 7.018, 13.805 6.485 C 13.256 4.352, 11.526 2.668, 9.366 2.163 C 8.735 2.016, 7.853 1.972, 7.227 2.057 M 7.786 4.019 C 7.446 4.107, 7.152 4.383, 7.053 4.710 C 6.994 4.905, 6.992 4.972, 7.001 6.576 L 7.009 8.240 7.093 8.420 C 7.165 8.574, 7.303 8.726, 8.039 9.460 C 9.052 10.471, 9.079 10.491, 9.501 10.491 C 9.736 10.492, 9.793 10.481, 9.940 10.408 C 10.138 10.311, 10.335 10.110, 10.427 9.911 C 10.478 9.801, 10.491 9.714, 10.491 9.501 C 10.491 9.093, 10.451 9.033, 9.664 8.240 L 9.008 7.580 8.999 6.154 L 8.991 4.728 8.888 4.538 C 8.716 4.221, 8.423 4.032, 8.068 4.008 C 7.965 4.001, 7.838 4.006, 7.786 4.019 M 0.013 8 C 0.013 8.314, 0.017 8.438, 0.022 8.276 C 0.028 8.114, 0.028 7.858, 0.022 7.706 C 0.017 7.554, 0.012 7.686, 0.013 8"
                      fill-rule="evenodd"
                    ></path>
                  </svg>{" "}
                  Tomorrow at 8:25 AM
                </div>
                <hr />
                <div className={cx("row-address-item")}>
                  {cartItems.map((item) => (
                    <Container className={cx("cart-item")}>
                      <Row>
                        <Col md={4} className={cx("cart-image-product")}>
                          <Card.Img
                            variant="top"
                            src={item.product.thumbnail}
                            alt={item.product.product_name}
                            className={cx("cart-image-product-img")}
                          />
                        </Col>
                        <Col md={5} className={cx("cart-item-info")}>
                          <Card.Body>
                            <Card.Title className={cx("cart-item-text")}>
                              {item.product.product_name}
                            </Card.Title>
                            <Card.Text className={cx("cart-item-text")}>
                              Size: {item.product.size}
                            </Card.Text>
                          </Card.Body>
                        </Col>
                        <Col md={3} className={cx("cart-item-info")}>
                          <Card.Body>
                            <Card.Text className={cx("cart-item-text")}>
                              ${item.product.cost_price}
                            </Card.Text>
                          </Card.Body>
                        </Col>
                      </Row>
                      <Row className={cx("cart-hr")}></Row>
                    </Container>
                  ))}
                </div>
                <hr />
                <div className={cx("row-add-tip")}>
                  <TipSelector
                    selectedTip={tipPercentCheckout}
                    onTipChange={handleTipChange}
                  />
                </div>
                <hr />
                <div className={cx("row-add-tip")}>
                  <OrderSummary
                    subtotal={subtotal}
                    tipAmount={calculateTip()}
                    tipPercent={tipPercentCheckout}
                  />
                </div>
              </Col>
            </Row>
            <Row className={cx("row-payment")}>
              <Col>
                <Button className={cx("btn-paymen")} onClick={handlePlaceOrder}>
                  Place order (${calculateOrderTotal()})
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {errors.length > 0 && (
                  <div className={cx("error-messages")}>
                    {errors.map((error, index) => (
                      <p key={index} className={cx("error-message")}>
                        {error}
                      </p>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Checkout;
