import React, { useState, useEffect } from "react";
import { Button, Table, Pagination } from "react-bootstrap";
import { getListAllAlbum, deleteAlbum } from "api/admin/album.api";
import { Link } from "react-router-dom";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import { toast } from "react-toastify";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const AdminAlbum = () => {
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Thêm state để lưu thông tin về mục muốn xóa

  useEffect(() => {
    const fetchData = async () => {
      const response = await getListAllAlbum(perPage, page);
      setProduct(response);

      console.log("check data", response);
    };

    fetchData();
  }, [perPage, page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getVisibleCategory = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return product.slice(startIndex, endIndex);
  };

  // Function xác nhận xóa
  const handleDeleteConfirm = async () => {
    try {
      // Gọi API xóa với id của mục cần xóa
      await deleteAlbum(itemToDelete);
      toast.success("Delete success!");
      setShowDeleteConfirm(false); // Ẩn modal
      // Cập nhật lại danh sách sau khi xóa
      const updatedList = product.filter((item) => item.id !== itemToDelete);
      setProduct(updatedList);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Hủy xác nhận xóa mục
  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false); // Ẩn modal
    setItemToDelete(null); // Đặt lại itemToDelete về null
  };

  // Xác định mục muốn xóa và hiển thị modal xác nhận xóa
  const handleDelete = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  return (
    <div className={cx("page-content")}>
      <div
        className={cx(
          "aiz-titlebar",
          "text-left",
          "mt-2",
          "pb-2",
          "px-3",
          "px-md-2rem",
          "border-bottom",
          "border-gray"
        )}
      >
        <div className={cx("row", "align-items-center")}>
          <div className={cx("col-md-6")}>
            <h1 className={cx("h3")}>All Image</h1>
          </div>
        </div>
      </div>
      <div className={cx("card")}>
        <div className={cx("card-header", "d-block", "d-md-flex")}>
          <h5 className={cx("mb-0", "h6")}>Image</h5>
        </div>
        <div className={cx("card-body")}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>#</th>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "30%" }}>Caption</th>
                <th style={{ width: "10%" }}>Thumbnail</th>
                <th style={{ width: "10%" }}>Album category</th>
                <th style={{ width: "15%" }}>Options</th>
              </tr>
            </thead>
            <tbody>
              {getVisibleCategory().map((data) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.image_name}</td>
                  <td>{data.caption}</td>
                  <td>
                    <img
                      src={data.image_url}
                      alt="Uploaded"
                      style={{ maxWidth: "100%" }}
                    />
                  </td>
                  <td>{data.album_id === 1 ? "Album slide" : "Album image our story"}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(data.id)}
                    >
                      Delete
                    </Button>
                    {showDeleteConfirm && (
                      <ConfirmDelete
                        show={showDeleteConfirm}
                        onCancel={handleDeleteCancel}
                        onConfirm={handleDeleteConfirm}
                        title="Delete Confirmation"
                        message="Are you sure to delete this?"
                      />
                    )}{" "}
                    {/* <Button
                      variant="primary"
                      size="sm"
                      as={Link}
                      to={`/admin/product/edit/${data.id}`}
                    >
                      Edit
                    </Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev onClick={() => handlePageChange(page - 1)} />
            {/* Loop to render page items */}
            {Array.from(
              { length: Math.ceil(product.length / perPage) },
              (_, i) => (
                <Pagination.Item
                  key={i + 1} // Add a unique key for each item
                  active={page === i + 1}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              )
            )}
            <Pagination.Next onClick={() => handlePageChange(page + 1)} />
            <Pagination.Last
              onClick={() =>
                handlePageChange(Math.ceil(product.length / perPage))
              }
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default AdminAlbum;
