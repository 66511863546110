import axios from "axios";

const TIME_OUT = 20000;

const axiosClient = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/", // Replace with your actual API endpoint
  baseURL: "https://admincafe.cuongdesign.net/api/", // Replace with your actual API endpoint
  timeout: TIME_OUT,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to handle configuring headers for file uploads
const configureFileUploadHeaders = (config) => {
  // Set appropriate headers for file uploads (e.g., multipart/form-data)
  config.headers = {
    "Content-Type": "multipart/form-data",
    // Add any other required headers for file uploads here
  };
  return config;
};

// Request interceptor - Check for file uploads and set headers accordingly
axiosClient.interceptors.request.use(
  (config) => {
    const hasFile = config.data instanceof FormData; // Check if data is FormData (indicating file upload)
    if (hasFile) {
      configureFileUploadHeaders(config);
    }

    let authenticationData = JSON.parse(localStorage.getItem("user_token"));

    if (authenticationData && authenticationData.accessToken) {
      config.headers["authorization"] =
        "Bearer " + authenticationData.accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor remains the same
axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log("check lỗi hệ thống: ", error);
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("user_token");
      window.location.href = "/auth/";
    } else if (error.code === "ERR_BAD_REQUEST") {
      return Promise.reject(error);
    } else {
      // window.location.href = "/404.html";
    }
  }
);

export default axiosClient;
