import { atom } from "recoil";
import { localStorageEffect } from "recoil/localStorage/localStorageEffect";

export const authenticationToken = atom({
  key: "authenticationToken",
  default: {
    accessToken: "",
    passwordExpiredRemainingDay: 0,
    name: "",
    role: "user",
  },
  effects: [localStorageEffect("user_token")],
});
