import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "page/Auth/Auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { register } from "api/auth/authen.api";
import { USER_PATH } from "constant/authPath";
import { SIGN_IN_PATH, PAGE_404_PATH } from "constant/appPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ErrorAlert from "components/ErrorAlert";
import {
  validateEmail,
  validatePassword,
} from "components/Validate/ValidateForm";

const cx = classNames.bind(styles);

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  // Thực hiện validate email
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setValidated(validateEmail(event.target.value));
    console.log("check validate email: ", validated);
  };

  // Thực hiện validate password
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setValidated(validatePassword(event.target.value));
  };

  // Thực hiện check nhập lại pass đúng chưa
  const validatePasswordConfirm = (password, confirmPassword) => {
    // Kiểm tra mật khẩu và mật khẩu xác nhận có khớp nhau hay không
    return password === confirmPassword;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    console.log(validated);
    try {
      const role = "user";
      const response = await register(
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        passwordConfirmation,
        role
      );
      // Xử lý thành công

      navigate(USER_PATH);
      console.log(response);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        setErrors(errors);
      } else {
        navigate(PAGE_404_PATH);
      }
    }
  };
  return (
    <>
      <Container className={cx("container-account")}>
        <Row className={cx("row-account")}>
          <Col>
            <h1 className={cx("account-text")}>CREATE AN ACCOUNT</h1>
            {errors.length > 0 && <ErrorAlert errors={errors} />}
          </Col>
        </Row>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className={cx("auth-form-item")}>
            <Col>
              <Form.Label>First Name</Form.Label>
              <FormGroup>
                <FormControl
                  type="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Form.Label>Last Name</Form.Label>
            <FormGroup>
              <FormControl
                type="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </FormGroup>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Col>
              <Form.Label>Phone number</Form.Label>
              <FormGroup>
                <FormControl
                  type="phoneNumber"
                  placeholder="Last Name"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Col>
              <Form.Label>Email</Form.Label>
              <FormGroup>
                <FormControl
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </FormGroup>
              {!validated && !validateEmail(email) && (
                <p style={{ color: "red" }}>
                  Please enter a valid email address.
                </p>
              )}
            </Col>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Col>
              <Form.Label>Password</Form.Label>
              <FormGroup className={cx("input-group")}>
                <FormControl
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <InputGroup.Text onClick={handleTogglePassword}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </InputGroup.Text>
              </FormGroup>
              {!validated && !validatePassword(password) && (
                <p style={{ color: "red" }}>
                  Password must be at least 8 characters long.
                </p>
              )}
            </Col>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Col>
              <Form.Label>Password Confirm</Form.Label>
              <FormGroup className={cx("input-group")}>
                <FormControl
                  type={showPasswordConfirm ? "text" : "password"}
                  placeholder="Enter password"
                  value={passwordConfirmation}
                  onChange={(e) => {
                    setPasswordConfirmation(e.target.value);
                    setValidated(
                      validatePasswordConfirm(password, e.target.value)
                    );
                  }}
                  required
                />
                <InputGroup.Text onClick={handleTogglePasswordConfirm}>
                  {showPasswordConfirm ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </InputGroup.Text>
              </FormGroup>
              {!validated &&
                !validatePasswordConfirm(password, passwordConfirmation) && (
                  <p style={{ color: "red" }}>
                    Confirmation password does not match..
                  </p>
                )}
            </Col>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Col>
              <FormGroup>
                <Form.Label className={cx("row-to-sign-in")}>
                  Already have an account?{" "}
                  <Link to={SIGN_IN_PATH} className={cx("text-sign-in")}>
                    Log In
                  </Link>
                </Form.Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className={cx("auth-form-item")}>
            <Col>
              <FormGroup>
                <Button variant="primary" type="submit">
                  Register
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Register;
