import React from "react";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const CheckboxInput = ({ id, name, checked, onChange }) => {
  return (
    <label
      style={{ backgroundColor: "white", color: "black", cursor: "pointer" }}
    >
      <input
        className={cx("hummingbird-end-node")}
        type="checkbox"
        id={id}
        value={id}
        name={name}
        checked={checked}
        onChange={onChange} // Truyền hàm xử lý sự kiện onChange từ component cha
      />{" "}
      {name}
    </label>
  );
};

export default CheckboxInput;
