import axiosClient from "api/config/axiosClient";

// API cho phần bài viết
const getListAllAttribute = () => {
  const url = "admin/attributes";
  return axiosClient.get(url);
};

const getListPageAttribute = (perPage, page) => {
  const url = "admin/attributes/list";
  return axiosClient.get(url, {
    perPage,
    page,
  });
};

const getAttributeById = (id) => {
  const url = `/admin/attributes/${id}`;
  return axiosClient.get(url);
};

const addNewAttribute = (name, option_display, delete_flag) => {
  const url = "admin/attributes";
  return axiosClient.post(url, {
    name,
    option_display,
    delete_flag,
  });
};

const updateAttribute = (id, name, delete_flag) => {
  const url = `/admin/attributes/${id}`;
  return axiosClient.put(url, {
    name,
    delete_flag,
  });
};

const addAttributeItem = (attribute_id, name, is_free, price) => {
  const url = `/admin/attributes/item`;
  return axiosClient.post(url, {
    attribute_id, name, is_free, price
  });
};

const deleteAttributeItem = (id) => {
  const url = `/admin/attributes/item/${id}`;
  return axiosClient.delete(url);
};

export {
  getListAllAttribute,
  addNewAttribute,
  getListPageAttribute,
  getAttributeById,
  updateAttribute,
  addAttributeItem,
  deleteAttributeItem
};
