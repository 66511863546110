import React from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "page/Application/Cart/Cart.module.scss";

const cx = classNames.bind(styles);

const OrderSummary = ({ subtotal, tipAmount, tipPercent }) => {
  // Hàm tính tổng tiền đơn hàng
  const calculateOrderTotal = () => {
    return subtotal + tipAmount;
  };

  return (
    <div className={cx("order-summary")}>
      <h2 className={cx("tip-title")}>ORDER SUMMARY</h2>
      <Row className={cx("row-summary-item")}>
        <Col md={8} sm={12}>
          Subtotal
        </Col>
        <Col md={4} sm={12}>
          ${subtotal}
        </Col>
      </Row>
      <Row className={cx("row-summary-item")}>
        <Col md={8} sm={12}>
          Tip ({tipPercent}%)
        </Col>
        <Col md={4} sm={12}>
          ${tipAmount}
        </Col>
      </Row>
      <Row className={cx("row-summary-item")}>
        <Col md={8} sm={12}>
          Estimated Order Total
        </Col>
        <Col md={4} sm={12}>
          ${calculateOrderTotal()}
        </Col>
      </Row>
      <Row className={cx("row-summary-item")}>
        <Col className={cx("text-note")}>
          Additional taxes and fees will be calculated at checkout
        </Col>
      </Row>
    </div>
  );
};

export default OrderSummary;
