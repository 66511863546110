import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import { addNewCategory, getCategoryById, updateCategoryById } from "api/admin/post.api";
import { ADMIN_PATH, ADMIN_CATEGORY_PATH } from "constant/authPath";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { InputText, InputPulldown } from "components/FormAdmin";

const cx = classNames.bind(styles);

const options = [
  { value: '0', label: 'Draft' },
  { value: '1', label: 'Public' },
];

const AdminCategoryNew = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (isEditMode) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await getCategoryById(id);
      setName(response.name);
      setDescription(response.description);
      setStatus(response.status);
    } catch (error) {
      toast.error('Thực hiện xóa thất bại và có lỗi: '.error.message);
      console.error("Lỗi khi lấy dữ liệu mục chỉnh sửa:", error);
    }
  };

  const { id } = useParams(); // Lấy ID từ URL
  const isEditMode = !!id;


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        const response = await updateCategoryById(id, name, description, status);
        window.location.href = ADMIN_PATH + "/" + ADMIN_CATEGORY_PATH;
      } else {
        const response = await addNewCategory(name, description, status);
        window.location.href = ADMIN_PATH + "/" + ADMIN_CATEGORY_PATH;
      }

      // Xử lý thành công, ví dụ: chuyển hướng người dùng đến trang chi tiết bài viết mới
    } catch (error) {
      toast.error('Thực hiện xóa thất bại và có lỗi: ' + error.response);
      console.error("Lỗi khi tạo danh mục:", error);
      // Xử lý lỗi, ví dụ: hiển thị thông báo lỗi cho người dùng
    }
  };

  return (
    <div className={cx("page-content")}>
      <div
        className={cx(
          "aiz-titlebar",
          "text-left",
          "mt-2",
          "pb-2",
          "px-3",
          "px-md-2rem",
          "border-bottom",
          "border-gray"
        )}
      >
        <div className={cx("row", "align-items-center")}>
          <div className={cx("col-md-6")}>
            <h1 className={cx("h3")}>{isEditMode ? "Edit category" : "Add new category"}</h1>
          </div>
          <div className={cx("col-md-6", "text-md-right")}>

          </div>
        </div>
      </div>
      <div className={cx("card")}>
        <div className={cx("card-header", "d-block", "d-md-flex")}>
          <h5 className={cx("mb-0", "h6")}>Category Information</h5>
        </div>
        <div className={cx("card-body")}>
          <Form onSubmit={handleSubmit}>
            <InputText
              label="Catogory name"
              id="name"
              value={name}
              placeholder="Enter a category name"
              maxLength={255}
              onChange={(e) => setName(e.target.value)}
            />
            <InputText
              label="Description"
              id="description"
              value={description}
              placeholder="Enter a detailed category description"
              onChange={(e) => setDescription(e.target.value)}
            />
            <InputPulldown
              label="Status"
              id="status"
              name="status"
              value={status}
              options={options}
              onChange={(e) => setStatus(e.target.value)}
            />
            <div className={cx("form-group", "mb-0", "text-right")}>
              <button type="submit" className={cx("btn", "btn-primary")}>{isEditMode ? "Update" : "Add"}</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminCategoryNew;
