import React, { useState, useEffect } from "react";
import { Container, Row, Button, Form, FormGroup, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";
import { addNewSetting, getSettingById, updateSettingById } from "api/admin/setting.api";
import { ADMIN_PATH, ADMIN_SETTING_PATH } from "constant/authPath";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);

const AdminSettingNew = () => {
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (isEditMode) {
            // Nếu ở chế độ chỉnh sửa, lấy thông tin của mục cần chỉnh sửa và điền vào các trường
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await getSettingById(id);
            setKey(response.key);
            setValue(response.value);
            setDescription(response.description);
        } catch (error) {
            toast.error('Thực hiện xóa thất bại và có lỗi: '.error.message);
            console.error("Lỗi khi lấy dữ liệu mục chỉnh sửa:", error);
        }
    };

    const { id } = useParams(); // Lấy ID từ URL
    const isEditMode = !!id;
    console.log("Đang ở chế độ chỉnh sửa ID: ", id);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                const response = await updateSettingById(id, key, value, description);
                window.location.href = ADMIN_PATH + "/" + ADMIN_SETTING_PATH;
            } else {
                const response = await addNewSetting(key, value, description);
                window.location.href = ADMIN_PATH + "/" + ADMIN_SETTING_PATH;
            }


        } catch (error) {
            toast.error('Thực hiện xóa thất bại và có lỗi: ' + error.response);
            console.error("Lỗi khi tạo bài viết:", error);
        }
    };

    return (
        <Container fluid>
            <Row className="flex-">
                <Col sm={6}>
                    <h2>{isEditMode ? "Chỉnh sửa thiết định" : "Thêm mới thiết định"}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form onSubmit={handleSubmit}>
                        {!isEditMode && (
                            <FormGroup>
                                <Form.Label for="key">Key thiết định:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="key"
                                    name="key"
                                    value={key}
                                    placeholder="Nhập key thiết định"
                                    onChange={(e) => setKey(e.target.value)}
                                />
                            </FormGroup>
                        )}

                        {isEditMode && (
                            <FormGroup>
                                <Form.Label for="key">Key thiết định: {key}</Form.Label>
                            </FormGroup>
                        )}

                        <FormGroup>
                            <Form.Label for="key">Giá trị thiết định:</Form.Label>
                            <Form.Control
                                type="text"
                                id="value"
                                name="value"
                                value={value}
                                placeholder="Nhập giá trị thiết định"
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Form.Label for="description">Mô tả chi tiết thiết định:</Form.Label>
                            <Form.Control
                                type="text"
                                id="description"
                                name="description"
                                value={description}
                                placeholder="Nhập mô tả chi tiết thiết định"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>

                        <br />
                        <FormGroup>
                            <Button variant="primary" type="submit">
                                {isEditMode ? "Cập nhật" : "Thêm mới"}
                            </Button>
                        </FormGroup>
                    </Form>
                </Col>
                <Col sm={4}>Thông tin khác:</Col>
            </Row>
        </Container>
    );
};

export default AdminSettingNew;
