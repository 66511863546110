import axiosClient from "api/config/axiosClient";

const getListAllSettings = (perPage, page) => {
    const url = "admin/general-settings";
    return axiosClient.get(url, {
        perPage,
        page,
    });
};

const addNewSetting = (key, value, description) => {
    const url = "admin/general-settings";
    return axiosClient.post(url, {
        key, value, description
    });
};


const deleteSetting = (id) => {
    const url = `/admin/general-settings/${id}`;
    return axiosClient.delete(url);
};

const getSettingById = (id) => {
    const url = `/admin/general-settings/${id}`;
    return axiosClient.get(url);
};

const updateSettingById = (id, key, value, description) => {
    const url = `/admin/general-settings/${id}`;
    return axiosClient.put(url, { key, value, description });
};




export { getListAllSettings, addNewSetting, deleteSetting, getSettingById, updateSettingById }
