import React, { useState, useEffect } from "react";

// Component chứa mã JavaScript để lấy địa chỉ từ vị trí GPS
const useGetAddressLocal = () => {
  // Sử dụng useState hook để lưu trữ địa chỉ từ vị trí GPS
  const [address, setAddress] = useState("");

  // Sử dụng useEffect hook để gọi API lấy địa chỉ khi component được render
  useEffect(() => {
    // Kiểm tra xem trình duyệt có hỗ trợ Geolocation không
    if (!navigator.geolocation) {
      console.error("Trình duyệt của bạn không hỗ trợ Geolocation.");
      return null;
    }

    // Lấy vị trí hiện tại của người dùng
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Lấy tọa độ vĩ độ và kinh độ
        const { latitude, longitude } = position.coords;

        // Gọi API của Google Maps Geocoding để lấy địa chỉ từ tọa độ
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDK9n0qOIfOV-ihrIMzchVj46DBVAnthpw`
        )
          .then((response) => response.json())
          .then((data) => {
            // Lấy địa chỉ từ dữ liệu nhận được và cập nhật vào state
            setAddress(data.results[0].formatted_address);
          })
          .catch((error) => {
            console.error("Lỗi khi lấy địa chỉ từ vị trí GPS:", error);
          });
      },
      (error) => {
        console.error("Lỗi khi lấy vị trí GPS:", error);
      }
    );
  }, []); // Truyền mảng rỗng để chỉ gọi useEffect một lần sau khi component được render

  // Render địa chỉ từ vị trí GPS
  return address;
};

export default useGetAddressLocal;
