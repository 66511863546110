import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getListAllUser, deleteUser } from "api/admin/user.api";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import { toast } from "react-toastify";

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Thêm state để lưu thông tin về mục muốn xóa

  useEffect(() => {
    // Lấy dữ liệu user từ API hoặc mock data
    const fetchData = async () => {
      const response = await getListAllUser(perPage, page);
      //   const data = await response.json();
      setUsers(response);

      console.log("check data", response);
    };

    fetchData();
  }, []);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getVisibleUsers = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return users.slice(startIndex, endIndex);
  };

  // Function xác nhận xóa
  const handleDeleteConfirm = async () => {
    try {
      // Gọi API xóa với id của mục cần xóa
      await deleteUser(itemToDelete);
      toast.success("Thực hiện thành công");
      setShowDeleteConfirm(false); // Ẩn modal
      // Cập nhật lại danh sách sau khi xóa
      const updatedList = users.filter((item) => item.id !== itemToDelete);
      setUsers(updatedList);
    } catch (error) {
      toast.error("Thực hiện xóa thất bại và có lỗi: ".error.message);
    }
  };

  // Hủy xác nhận xóa mục
  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false); // Ẩn modal
    setItemToDelete(null); // Đặt lại itemToDelete về null
  };

  // Xác định mục muốn xóa và hiển thị modal xác nhận xóa
  const handleDelete = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  return (
    <Container fluid>
      <Row className="flex-">
        <Col sm={6}>
          <h2>Users</h2>
        </Col>
        <Col sm={6} className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            size="lg"
            type="button"
            as={Link}
            to="/admin/user/new"
          >
            Add new user
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone number</th>
                  <th>Address</th>
                  <th>Role</th>
                  <th>Option</th>
                </tr>
              </thead>
              <tbody>
                {getVisibleUsers().map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      {user.last_name} {user.first_name}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.phone_number}</td>
                    <td>{user.address}</td>
                    <td>{user.role}</td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(user.id)}
                      >
                        Delete
                      </Button>
                      {showDeleteConfirm && (
                        <ConfirmDelete
                          show={showDeleteConfirm}
                          onCancel={handleDeleteCancel}
                          onConfirm={handleDeleteConfirm}
                          title="Xác nhận xóa"
                          message="Bạn có chắc chắn muốn xóa?"
                        />
                      )}{" "}
                      <Button
                        variant="primary"
                        size="sm"
                        as={Link} // Sử dụng thẻ <Link> thay vì <a> để chuyển hướng
                        to={`/admin/user/edit/${user.id}`} // Đặt đường dẫn đến màn hình chỉnh sửa và truyền ID
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination>
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev onClick={() => handlePageChange(page - 1)} />
              <Pagination.Item
                active={page === 1}
                onClick={() => handlePageChange(1)}
              >
                1
              </Pagination.Item>
              {/* ... */}
              <Pagination.Next onClick={() => handlePageChange(page + 1)} />
              <Pagination.Last
                onClick={() =>
                  handlePageChange(Math.ceil(users.length / perPage))
                }
              />
            </Pagination>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminUser;
