import axiosClient from "api/config/axiosClient";

// Flow register account
const register = (
  first_name,
  last_name,
  phone_number,
  email,
  password,
  password_confirmation,
  role
) => {
  const url = "auth/register";
  return axiosClient.post(url, {
    first_name,
    last_name,
    phone_number,
    email,
    password,
    password_confirmation,
    role,
  });
};

// Flow login
const login = (email, password) => {
  const url = "auth/login";
  return axiosClient.post(url, { email, password });
};

const profile = () => {
  const url = "auth/profile";
  return axiosClient.get(url);
};

// Flow change password

export { register, login, profile };
