import React, { useState, useEffect } from "react";
import { Form, Tab, Nav, Row, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import {
  addNewProduct,
  getProductById,
  updateProduct,
  addProductAttribute,
  getListAttributeByProductId,
} from "api/admin/product.api";
import { addNewImage } from "api/admin/image.api";
import { ADMIN_PATH, ADMIN_PRODUCT_PATH } from "constant/authPath";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  InputText,
  InputPulldown,
  InputNumber,
  InputTextArea,
  InputTextSearchAttribute,
} from "components/FormAdmin";
import ProductCategory from "layouts/AdminLayout/ProductCategory/ProductCategory";
import { check } from "prettier";

const cx = classNames.bind(styles);

// const optionBrand = [
//   { value: "0", label: "Select Brand" },
//   { value: "1", label: "The Coffee House" },
//   { value: "2", label: "Highlands Coffee" },
//   { value: "3", label: "Vinacafe" },
// ];

const statusList = [
  { value: "1", label: "Public" },
  { value: "0", label: "Draft" },
];

const AdminProductNew = () => {
  const [name, setName] = useState("");
  // const [brand, setBrand] = useState("");
  const [costPrice, setCostPrice] = useState(0.0);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("1");
  const [attributes, setAttributes] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [categoryIds, setCategoryId] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataAttribute, setDataAttribute] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await addNewImage(formData);
      setThumbnail(response.path);
      toast.success("upload image success!");
    } catch (error) {
      toast.error("Error uploading image:" + error.message);
    }
  };

  const handleAddAttribute = (attribute) => {
    console.log("check attribute: ", attribute);
    setAttributes(attribute);
  };

  useEffect(() => {
    if (isEditMode) {
      fetchData();
      fetchDataAttribute();
    }
  }, []);

  const fetchDataAttribute = async () => {
    try {
      const response = await getListAttributeByProductId(id);
      setDataAttribute(response);
      console.log("check getListAttributeByProductId: ", response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getProductById(id);
      console.log("check edit product data: ", response);
      setName(response.product_name);
      setCostPrice(response.cost_price);
      setDescription(response.information);
      setStatus(response.is_active);
      setThumbnail(response.thumbnail);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { id } = useParams(); // Lấy ID từ URL
  const isEditMode = !!id;

  const handleCategoryChange = (updatedCategories) => {
    setCategoryId(updatedCategories);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payloadData = {
      thumbnail: thumbnail,
      name: name,
      description: description,
      cost_price: costPrice,
      status: status,
      brand: "Test",
      category_ids: categoryIds,
    };

    console.log("check payload data update", payloadData);

    try {
      if (isEditMode) {
        const response = await updateProduct(id, payloadData);
        toast.success("Update product success!");
        window.location.href = ADMIN_PATH + "/" + ADMIN_PRODUCT_PATH;
      } else {
        const response = await addNewProduct(payloadData);
        toast.success("Add new product success!");
        window.location.href = ADMIN_PATH + "/" + ADMIN_PRODUCT_PATH;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePriceChange = (index, value) => {
    const updatedAttributes = { ...attributes };
    updatedAttributes.items[index].price = value;
    setAttributes(updatedAttributes);
  };

  const handleAddAttributeToProduct = async () => {
    const payloadDataAttribute = {
      product_code: id,
      attribute_id: attributes.id,
      items: attributes.items,
    };
    try {
      await addProductAttribute(payloadDataAttribute);
      setAttributes(null);
      fetchDataAttribute();
      toast.success("Add Product Attribute success!");
    } catch (error) {
      toast.error(error);
    }
    console.log("data add payloadDataAttribute: ", payloadDataAttribute);
  };

  return (
    <div className={cx("page-content")}>
      <div
        className={cx(
          "aiz-titlebar",
          "text-left",
          "mt-2",
          "pb-2",
          "px-3",
          "px-md-2rem",
          "border-bottom",
          "border-gray"
        )}
      >
        <div className={cx("row", "align-items-center")}>
          <div className={cx("col-md-6")}>
            <h1 className={cx("h3")}>
              {isEditMode ? "Edit Product" : "Add new Product"}
            </h1>
          </div>
          <div className={cx("col-md-6", "text-md-right")}></div>
        </div>
      </div>
      <div className={cx("d-sm-flex")}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="general">
          <div
            className={cx("page-side-nav", "c-scrollbar-light", "px-3", "py-2")}
          >
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="general">General</Nav.Link>
              </Nav.Item>
              {isEditMode && (
                <Nav.Item>
                  <Nav.Link eventKey="ingredients">Ingredients</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </div>
          <div
            className={cx(
              "flex-grow-1",
              "p-sm-3",
              "p-lg-2rem",
              "mb-2rem",
              "mb-md-0"
            )}
          >
            <form onSubmit={handleSubmit}>
              {/* Tab content: Product Information */}
              <Tab.Content>
                <Tab.Pane eventKey="general" className={cx("tab-pane", "fade")}>
                  <h5
                    className={cx("mb-3", "pb-3", "fs-17", "fw-700")}
                    style={{ borderBottom: "1px dashed #e4e5eb" }}
                  >
                    Product Information
                  </h5>
                  <div className={cx("w-100")}>
                    <div className={cx("row")}>
                      <div className={cx("col-xxl-7", "col-xl-6")}>
                        <InputText
                          label="Product name"
                          id="name"
                          value={name}
                          placeholder="Enter a product name"
                          maxLength={255}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {/* <InputPulldown
                          label="Brand"
                          id="brand"
                          name="brand"
                          value={brand}
                          options={optionBrand}
                          onChange={(e) => setBrand(e.target.value)}
                        /> */}
                        <InputNumber
                          label="Cost Price"
                          id="costPrice"
                          value={costPrice}
                          placeholder="0.00"
                          maxLength={255}
                          onChange={(e) => setCostPrice(e.target.value)}
                        />
                        <InputTextArea
                          label="Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <InputPulldown
                          label="Status"
                          id="status"
                          name="status"
                          value={status}
                          options={statusList}
                          onChange={(e) => setStatus(e.target.value)}
                        />
                        <div className={cx("form-group", "row")}>
                          <label
                            htmlFor={id}
                            className={cx("col-md-3", "col-form-label")}
                          >
                            Thumbnail
                          </label>
                          <div className={cx("col-md-9")}>
                            <input type="file" onChange={handleFileChange} />
                            <div onClick={handleUpload}>Upload</div>
                            {thumbnail && (
                              <div>
                                <img
                                  src={thumbnail}
                                  alt="Uploaded"
                                  style={{ maxWidth: "100%" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={cx("col-xxl-5", "col-xl-6")}>
                        <ProductCategory
                          onChange={handleCategoryChange}
                          productId={id}
                        />
                      </div>
                    </div>
                    <div className={cx("mt-4", "text-right")}>
                      {/* <button
                  type="submit"
                  name="button"
                  value="unpublish"
                  className={cx(
                    "mx-2",
                    "btn",
                    "btn-light",
                    "w-230px",
                    "btn-md",
                    "rounded-2",
                    "fs-14",
                    "fw-700",
                    "shadow-secondary",
                    "border-soft-secondary",
                    "action-btn"
                  )}
                >
                  Save &amp; Unpublish
                </button> */}
                      <button
                        type="submit"
                        name="button"
                        value="publish"
                        class={cx(
                          "mx-2",
                          "btn",
                          "btn-success",
                          "w-230px",
                          "btn-md",
                          "rounded-2",
                          "fs-14",
                          "fw-700",
                          "shadow-success",
                          "action-btn"
                        )}
                      >
                        Save &amp; Publish
                      </button>
                    </div>
                  </div>
                </Tab.Pane>
                {/* Tab content: Cac thanh phan cua san pham */}
                <Tab.Pane eventKey="ingredients">
                  <h5
                    className={cx("mb-3", "pb-3", "fs-17", "fw-700")}
                    style={{ borderBottom: "1px dashed #e4e5eb" }}
                  >
                    Product Ingredients
                  </h5>
                  <div className={cx("w-100")}>
                    {/* Start Vùng hiển thị item attribute*/}
                    {dataAttribute && dataAttribute.length > 0 && dataAttribute.map((attribute, attributeIndex) => (
                      <div
                        key={attributeIndex}
                        className={cx("attribute-group")}
                      >
                        <h6>{attribute.attribute_name}</h6>
                        {attribute.items.map((item, itemIndex) => (
                          <Row
                            key={itemIndex}
                            style={{ marginBottom: "5px", paddingLeft: "20px" }}
                          >
                            <Col sm={3}>Item Name: {item.item_name}</Col>
                            <Col sm={9}>Item price: ${item.price}</Col>
                          </Row>
                        ))}
                      </div>
                    ))}
                    {/* End Vùng hiển thị item attribute */}
                  </div>
                  <div
                    className={cx("w-100")}
                    style={{
                      marginTop: "15px",
                      paddingBottom: "10px",
                      borderTop: "1px solid #0abb75",
                    }}
                  >
                    <div
                      className={cx("form-group", "row", "gutters-5")}
                      style={{
                        marginTop: "15px",
                        paddingBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor={id}
                        className={cx("col-md-3", "col-form-label")}
                      >
                        Select ingredients
                      </label>
                      <div className={cx("col-md-9")}>
                        <InputTextSearchAttribute
                          onSelect={handleAddAttribute}
                        />
                      </div>
                    </div>
                    {/* Start Vùng hiển thị item attribute*/}
                    {attributes !== null && (
                      <>
                        <div className={cx("form-group", "row", "gutters-5")}>
                          <label
                            htmlFor={id}
                            className={cx("col-md-3", "col-form-label")}
                          >
                            Attribute name
                          </label>
                          <div className={cx("col-md-9")}>
                            {attributes.name}
                          </div>
                        </div>
                        <div
                          className={cx(
                            "form-group",
                            "row",
                            "gutters-5",
                            "border-top"
                          )}
                        >
                          <label
                            htmlFor={id}
                            className={cx("col-md-3", "col-form-label")}
                          >
                            List Item
                          </label>
                          <div className={cx("col-md-9")}>
                            {attributes.items.map((item, index) => (
                              <Row style={{ marginBottom: "5px" }}>
                                <Col sm={3}>Item Name: {item.name}</Col>
                                <Col sm={9}>
                                  Item price:{" "}
                                  <input
                                    type="number"
                                    value={item.price}
                                    onChange={(e) =>
                                      handlePriceChange(index, e.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </div>
                        <div className={cx("form-group", "row", "gutters-5")}>
                          <div
                            className={cx("btn-add-attribute", "col-md-3")}
                            onClick={handleAddAttributeToProduct}
                          >
                            Add attribute to Product
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* End Vùng hiển thị item attribute */}
                </Tab.Pane>
              </Tab.Content>
            </form>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AdminProductNew;
