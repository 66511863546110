import React from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "page/Application/Cart/Cart.module.scss";

const cx = classNames.bind(styles);

const tipOptions = [5, 10, 15, 20];

const TipSelector = ({ selectedTip, onTipChange }) => {
  return (
    <div className={cx("tip-selector")}>
      <div className={cx("tip-title")}>ADD A TIP</div>
      {tipOptions.map((percent) => (
        <Button
          key={percent}
          variant="outline-primary"
          onClick={() => onTipChange(percent)}
          className={cx("tip-button", { active: selectedTip === percent })}
        >
          {percent}%
        </Button>
      ))}
    </div>
  );
};

export default TipSelector;
