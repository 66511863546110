import React from "react";

function PaymentDetails({ method }) {
    return (
        <div className="payment-details">
            {/* Hiển thị chi tiết phương thức thanh toán */}
            <h2>{method.name}</h2>
            <p>{method.description}</p>
            {/* Hiển thị các thông tin khác về phương thức thanh toán */}
        </div>
    );
}

export default PaymentDetails;
