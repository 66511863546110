import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import useCheckRouterValid from "hook/useCheckRouterValid";
import useCheckRoleAdmin from "hook/useCheckRoleAdmin";
import NotFound from "page/NotFound";
import {
  HOMEPAGE,
  PAGE_404_PATH,
  CART_PATH,
  CHECKOUT_PATH,
  ORDER_SUCCESS_PATH,
  HOME_PAGE_TOP,
  GIFT_CARDS_PAGE,
  CONTACT_US_PAGE,
} from "constant/appPath";
import Application from "page/Application";
import Home from "page/Application/Home";
import Admin from "page/Admin";
import Dashboard from "page/Admin/Dashboard";
import AdminUser from "page/Admin/AdminUser";
import Auth from "page/Auth";
import SignIn from "page/Auth/SignIn";
import SignUp from "page/Auth/Register";
import {
  ADMIN_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_NEW_PATH,
  ADMIN_POST_EDIT_PATH,
  ADMIN_CATEGORY_PATH,
  ADMIN_CATEGORY_NEW_PATH,
  ADMIN_CATEGORY_EDIT_PATH,
  ADMIN_ATTRIBUE_PATH,
  ADMIN_ATTRIBUE_NEW_PATH,
  ADMIN_ATTRIBUE_EDIT_PATH,
  ADMIN_ATTRIBUE_ADD_ITEM_PATH,
  ADMIN_USER_PATH,
  ADMIN_USER_NEW_PATH,
  ADMIN_USER_EDIT_PATH,
  ADMIN_SETTING_PATH,
  ADMIN_SETTING_NEW_PATH,
  ADMIN_SETTING_EDIT_PATH,
  ADMIN_PRODUCT_PATH,
  ADMIN_PRODUCT_NEW_PATH,
  ADMIN_PRODUCT_EDIT_PATH,
  USER_PATH,
  USER_PRODUCT_OF_INTEREST,
  USER_VIEW_PRODUCT,
  USER_MY_ORDER,
  USER_REWARD_POINTS,
  USER_MY_VOUCHER,
  USER_INTRODUCTORY_DIAGRAM,
  ADMIN_ORDER_PATH,
  ADMIN_ALBUM_PATH,
  ADMIN_ALBUM_NEW_PATH,
} from "constant/authPath";
import User from "page/User";
import Account from "page/User/Account";
import ProductOfInterest from "page/User/ProductOfInterest";
import ViewProduct from "page/User/ViewProduct";
import MyOrder from "page/User/MyOrder";
import RewardPoints from "page/User/RewardPoints";
import MyVoucher from "page/User/MyVoucher";
import IntroductoryDiagram from "page/User/IntroductoryDiagram";
import AdminPost from "page/Admin/AdminPost";
import AdminPostNew from "page/Admin/AdminPostNew";
import AdminCategory from "page/Admin/AdminCategory";
import AdminCategoryNew from "page/Admin/AdminCategoryNew";
import AdminUserNew from "page/Admin/AdminUserNew";
import AdminSettings from "page/Admin/AdminSettings";
import AdminSettingNew from "page/Admin/AdminSettingNew";
import Page404 from "page/Page404";
import AdminProduct from "page/Admin/AdminProduct";
import AdminProductNew from "page/Admin/AdminProductNew";
import Cart from "page/Application/Cart";
import Checkout from "page/Application/Checkout";
import OrderSuccess from "page/Application/OrderSuccess";
import HomePage from "page/Application/HomePage";
import GiftCards from "page/Application/GiftCards";
import ContactUs from "page/Application/ContactUs";
import AdminAttributes from "page/Admin/AdminAttributes";
import AdminAttributesNew from "page/Admin/AdminAttributesNew";
import AdminAttributesAddItem from "page/Admin/AdminAttributesAddItem";
import AdminOrder from "page/Admin/AdminOrder";
import AdminAlbum from "page/Admin/AdminAlbum";
import AdminAlbumNew from "page/Admin/AdminAlbumNew";

function App() {
  const checkRouterValid = useCheckRouterValid();
  const checkRoleAdmin = useCheckRoleAdmin();
  console.log("Check checkRoleAdmin: ", checkRoleAdmin);

  return (
    <Router>
      <Routes>
        {/* Routes public */}
        <Route path={HOMEPAGE} element={<Application />}>
          <Route index element={<Home />} />
          <Route path={CART_PATH} element={<Cart />} />
          <Route path={CHECKOUT_PATH} element={<Checkout />} />
          <Route path={ORDER_SUCCESS_PATH} element={<OrderSuccess />} />
          <Route path={HOME_PAGE_TOP} element={<HomePage />} />
          <Route path={GIFT_CARDS_PAGE} element={<GiftCards />} />
          <Route path={CONTACT_US_PAGE} element={<ContactUs />} />
        </Route>
        <Route path={PAGE_404_PATH} element={<Page404 />} />

        {/* Routes private */}
        <Route path="/auth/" element={<Auth />}>
          <Route index element={<SignIn />} />
          <Route path="register" element={<SignUp />} />
        </Route>
        {!checkRouterValid && (
          <>
            <Route path={USER_PATH} element={<Navigate to="/auth/" />} />
            <Route
              path={USER_PRODUCT_OF_INTEREST}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={USER_VIEW_PRODUCT}
              element={<Navigate to="/auth/" />}
            />
            <Route path={USER_MY_ORDER} element={<Navigate to="/auth/" />} />
            <Route
              path={USER_REWARD_POINTS}
              element={<Navigate to="/auth/" />}
            />
            <Route path={USER_MY_VOUCHER} element={<Navigate to="/auth/" />} />
            <Route
              path={USER_INTRODUCTORY_DIAGRAM}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PRODUCT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PRODUCT_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PRODUCT_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route path={ADMIN_ORDER_PATH} element={<Navigate to="/auth/" />} />
            <Route path={ADMIN_ALBUM_PATH} element={<Navigate to="/auth/" />} />
            <Route
              path={ADMIN_ALBUM_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
          </>
        )}
        {(!checkRoleAdmin || !checkRouterValid) && (
          <>
            <Route path={ADMIN_PATH} element={<Navigate to="/auth/" />} />
            <Route path={ADMIN_POST_PATH} element={<Navigate to="/auth/" />} />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_POST_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_POST_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_CATEGORY_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_CATEGORY_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_CATEGORY_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_USER_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_USER_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_USER_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_SETTING_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_SETTING_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_SETTING_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_PRODUCT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_PRODUCT_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_PRODUCT_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_CATEGORY_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_CATEGORY_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_CATEGORY_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_EDIT_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ATTRIBUE_ADD_ITEM_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ORDER_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ALBUM_PATH}
              element={<Navigate to="/auth/" />}
            />
            <Route
              path={ADMIN_PATH + "/" + ADMIN_ALBUM_NEW_PATH}
              element={<Navigate to="/auth/" />}
            />
          </>
        )}
        {checkRouterValid && (
          <>
            {checkRoleAdmin && (
              <Route path={ADMIN_PATH} element={<Admin />}>
                <Route index element={<Dashboard />} />
                <Route path={ADMIN_POST_PATH} element={<AdminPost />} />
                <Route path={ADMIN_POST_NEW_PATH} element={<AdminPostNew />} />
                <Route path={ADMIN_POST_EDIT_PATH} element={<AdminPostNew />} />
                <Route path={ADMIN_CATEGORY_PATH} element={<AdminCategory />} />
                <Route
                  path={ADMIN_CATEGORY_NEW_PATH}
                  element={<AdminCategoryNew />}
                />
                <Route
                  path={ADMIN_CATEGORY_EDIT_PATH}
                  element={<AdminCategoryNew />}
                />
                <Route
                  path={ADMIN_ATTRIBUE_PATH}
                  element={<AdminAttributes />}
                />
                <Route
                  path={ADMIN_ATTRIBUE_NEW_PATH}
                  element={<AdminAttributesNew />}
                />
                <Route
                  path={ADMIN_ATTRIBUE_EDIT_PATH}
                  element={<AdminAttributesNew />}
                />
                <Route
                  path={ADMIN_ATTRIBUE_ADD_ITEM_PATH}
                  element={<AdminAttributesAddItem />}
                />
                <Route path={ADMIN_USER_PATH} element={<AdminUser />} />
                <Route path={ADMIN_USER_NEW_PATH} element={<AdminUserNew />} />
                <Route path={ADMIN_USER_EDIT_PATH} element={<AdminUserNew />} />
                <Route path={ADMIN_SETTING_PATH} element={<AdminSettings />} />
                <Route
                  path={ADMIN_SETTING_NEW_PATH}
                  element={<AdminSettingNew />}
                />
                <Route
                  path={ADMIN_SETTING_EDIT_PATH}
                  element={<AdminSettingNew />}
                />
                <Route path={ADMIN_PRODUCT_PATH} element={<AdminProduct />} />
                <Route
                  path={ADMIN_PRODUCT_NEW_PATH}
                  element={<AdminProductNew />}
                />
                <Route
                  path={ADMIN_PRODUCT_EDIT_PATH}
                  element={<AdminProductNew />}
                />
                <Route path={ADMIN_ORDER_PATH} element={<AdminOrder />} />
                <Route path={ADMIN_ALBUM_PATH} element={<AdminAlbum />} />
                <Route
                  path={ADMIN_ALBUM_NEW_PATH}
                  element={<AdminAlbumNew />}
                />
              </Route>
            )}
            <Route path={USER_PATH} element={<User />}>
              <Route index element={<Account />} />
              <Route
                path={USER_PRODUCT_OF_INTEREST}
                element={<ProductOfInterest />}
              />
              <Route path={USER_VIEW_PRODUCT} element={<ViewProduct />} />
              <Route path={USER_MY_ORDER} element={<MyOrder />} />
              <Route path={USER_REWARD_POINTS} element={<RewardPoints />} />
              <Route path={USER_MY_VOUCHER} element={<MyVoucher />} />
              <Route
                path={USER_INTRODUCTORY_DIAGRAM}
                element={<IntroductoryDiagram />}
              />
            </Route>
          </>
        )}

        {/* Not Found Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
