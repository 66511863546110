import axiosClient from "api/config/axiosClient";

// API get all product by category id
const getListAllProductByCategory = (category) => {
  const url = `app/products/category/${category}`;
  return axiosClient.get(url);
};

const getAttributeByProductId = (id) => {
  const url = `app/products/${id}/attribute`;
  return axiosClient.get(url);
};

export { getListAllProductByCategory, getAttributeByProductId };
