// Admin router
const ADMIN_PATH = "/admin";
const ADMIN_POST_PATH = "post";
const ADMIN_POST_NEW_PATH = "post/new";
const ADMIN_POST_EDIT_PATH = "post/edit/:id";
const ADMIN_PRODUCT_PATH = "product";
const ADMIN_PRODUCT_NEW_PATH = "product/new";
const ADMIN_PRODUCT_EDIT_PATH = "product/edit/:id";
const ADMIN_ORDER_PATH = "order";
const ADMIN_CATEGORY_PATH = "category";
const ADMIN_CATEGORY_NEW_PATH = "category/new";
const ADMIN_CATEGORY_EDIT_PATH = "category/edit/:id";
const ADMIN_ATTRIBUE_PATH = "attributes";
const ADMIN_ATTRIBUE_NEW_PATH = "attributes/new";
const ADMIN_ATTRIBUE_EDIT_PATH = "attributes/edit/:id";
const ADMIN_ATTRIBUE_ADD_ITEM_PATH = "attributes/:id/items";
const ADMIN_USER_PATH = "user";
const ADMIN_USER_NEW_PATH = "user/new";
const ADMIN_USER_EDIT_PATH = "user/edit/:id";
const ADMIN_SETTING_PATH = "setting";
const ADMIN_SETTING_NEW_PATH = "setting/new";
const ADMIN_SETTING_EDIT_PATH = "setting/edit/:id";
const ADMIN_ALBUM_PATH = "album";
const ADMIN_ALBUM_NEW_PATH = "album/new";
const ADMIN_ALBUM_EDIT_PATH = "album/edit/:id";

// User
const USER_PATH = "/account";
const USER_ADDRESS = "address";
const USER_PRODUCT_OF_INTEREST = "product-of-interest";
const USER_VIEW_PRODUCT = "viewed-product";
const USER_MY_ORDER = "my-order";
const USER_REWARD_POINTS = "reward-points";
const USER_MY_VOUCHER = "my-voucher";
const USER_INTRODUCTORY_DIAGRAM = "introductory-diagram";

export {
  ADMIN_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_NEW_PATH,
  ADMIN_POST_EDIT_PATH,
  ADMIN_USER_EDIT_PATH,
  ADMIN_CATEGORY_PATH,
  ADMIN_CATEGORY_NEW_PATH,
  ADMIN_CATEGORY_EDIT_PATH,
  ADMIN_ATTRIBUE_PATH,
  ADMIN_ATTRIBUE_NEW_PATH,
  ADMIN_ATTRIBUE_EDIT_PATH,
  ADMIN_ATTRIBUE_ADD_ITEM_PATH,
  ADMIN_USER_PATH,
  ADMIN_SETTING_PATH,
  ADMIN_SETTING_NEW_PATH,
  ADMIN_SETTING_EDIT_PATH,
  USER_PATH,
  ADMIN_USER_NEW_PATH,
  USER_ADDRESS,
  USER_PRODUCT_OF_INTEREST,
  USER_VIEW_PRODUCT,
  USER_MY_ORDER,
  USER_REWARD_POINTS,
  USER_MY_VOUCHER,
  USER_INTRODUCTORY_DIAGRAM,
  ADMIN_PRODUCT_PATH,
  ADMIN_PRODUCT_NEW_PATH,
  ADMIN_PRODUCT_EDIT_PATH,
  ADMIN_ORDER_PATH,
  ADMIN_ALBUM_PATH,
  ADMIN_ALBUM_NEW_PATH,
  ADMIN_ALBUM_EDIT_PATH,
};
