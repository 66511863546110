import axiosClient from "api/config/axiosClient";

const addNewProduct = (payloadData) => {
  const url = "admin/products";
  return axiosClient.post(url, { payloadData });
};

const addProductAttribute = (payloadData) => {
  const url = "admin/products/attribute";
  return axiosClient.post(url, { payloadData });
};

const updateProduct = (id, payloadData) => {
  const url = `/admin/products/${id}`;
  return axiosClient.put(url, { payloadData });
};

const getListAllProduct = (perPage, page) => {
  const url = "admin/products";
  return axiosClient.get(url, {
    perPage,
    page,
  });
};

const deleteProduct = (id) => {
  const url = `/admin/products/${id}`;
  return axiosClient.delete(url);
};

const getProductById = (id) => {
  const url = `/admin/products/${id}`;
  return axiosClient.get(url);
};

const getListCategoryByProductId = (id) => {
  const url = `/admin/products/${id}/category`;
  return axiosClient.get(url);
};

const getListAttributeByProductId = (id) => {
  const url = `/admin/products/${id}/attribute`;
  return axiosClient.get(url);
};

export {
  addNewProduct,
  getListAllProduct,
  deleteProduct,
  getProductById,
  getListCategoryByProductId,
  updateProduct,
  addProductAttribute,
  getListAttributeByProductId,
};
