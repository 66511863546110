import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Container, Row, Button, Form, FormGroup, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";
import { addNewPost, getPostById, updatePostById } from "api/admin/post.api";
import { ADMIN_PATH, ADMIN_POST_PATH } from "constant/authPath";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { getListAllCategory } from "api/admin/post.api";

const cx = classNames.bind(styles);

const AdminPostNew = () => {
  const [title, setTitle] = useState("");
  const [content1, setContent1] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (isEditMode) {
      // Nếu ở chế độ chỉnh sửa, lấy thông tin của mục cần chỉnh sửa và điền vào các trường
      fetchData();
    }
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getListAllCategory(100, 1); // Gọi API để lấy danh sách danh mục
      setCategories(response); // Cập nhật state categories
    } catch (error) {
      console.error("Lỗi khi lấy danh sách danh mục:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getPostById(id);
      setTitle(response.title);
      setContent1(response.content);
      setCategory(response.category);
      setStatus(response.status.toString());
    } catch (error) {
      toast.error('Thực hiện xóa thất bại và có lỗi: ' + error.message);
      console.error("Lỗi khi lấy dữ liệu mục chỉnh sửa:", error);
    }
  };

  const { id } = useParams(); // Lấy ID từ URL
  const isEditMode = !!id;


  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setContent1(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const content = encodeURIComponent(content1); // Mã hóa nội dung trước khi lưu vào DB
    const content = content1;
    try {
      if (isEditMode) {
        const response = await updatePostById(id, title, content, category, status);
        window.location.href = ADMIN_PATH + "/" + ADMIN_POST_PATH;
      } else {
        const response = await addNewPost(title, content, category, status);
        window.location.href = ADMIN_PATH + "/" + ADMIN_POST_PATH;
      }

    } catch (error) {
      toast.error('Thực hiện xóa thất bại và có lỗi: ' + error.response);
      console.error("Lỗi khi tạo bài viết:", error);
      // Xử lý lỗi, ví dụ: hiển thị thông báo lỗi cho người dùng
    }
  };

  return (
    <Container fluid>
      <Row className="flex-">
        <Col sm={6}>
          <h2>{isEditMode ? "Chỉnh sửa bài viết" : "Thêm mới bài viết"}</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Form.Label for="title">Tiêu đề:</Form.Label>
              <Form.Control
                type="text"
                id="title"
                name="title"
                value={title}
                placeholder="Nhập tiêu đề bài viết"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <br />
            <Form.Group as={Row} controlId="category">
              <Form.Label column sm="2">
                Danh mục:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Chọn danh mục</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="status">
              <Form.Label column sm="2">
                Status:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="0">Nháp</option>
                  <option value="1">Công khai</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <FormGroup>
              <Form.Label for="content">Nội dung:</Form.Label>
              <CKEditor editor={ClassicEditor} onChange={handleEditorChange} data={content1} />
            </FormGroup>
            <br />
            <FormGroup>
              <Button variant="primary" type="submit">
                {isEditMode ? "Cập nhật" : "Thêm mới"}
              </Button>
            </FormGroup>
          </Form>
        </Col>
        <Col sm={4}>Thông tin khác:</Col>
      </Row>
    </Container>
  );
};

export default AdminPostNew;
