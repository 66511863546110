import axiosClient from "api/config/axiosClient";

// API cho phần bài viết
const getListAllPost = (perPage, page) => {
  const url = "admin/posts";
  return axiosClient.get(url, {
    perPage,
    page,
  });
};

const addNewPost = (title, content, category, status) => {
  const url = "admin/posts";
  return axiosClient.post(url, {
    title,
    content,
    category,
    status,
  });
};

const deletePost = (id) => {
  const url = `/admin/posts/${id}`;
  return axiosClient.delete(url);
};

const getPostById = (id) => {
  const url = `/admin/posts/${id}`;
  return axiosClient.get(url);
};

const updatePostById = (id, title, content, category, status) => {
  const url = `/admin/posts/${id}`;
  return axiosClient.put(url, { title, content, category, status });
};


// API cho phần danh mục
const getListAllCategory = (perPage, page) => {
  const url = "admin/category";
  return axiosClient.get(url, {
    perPage,
    page,
  });
};


const addNewCategory = (name, description, status) => {
  const url = "admin/category";
  return axiosClient.post(url, {
    name, description, status
  });
};

const deleteCategory = (id) => {
  const url = `/admin/category/${id}`;
  return axiosClient.delete(url);
};

const getCategoryById = (id) => {
  const url = `/admin/category/${id}`;
  return axiosClient.get(url);
};

const updateCategoryById = (id, name, description, status) => {
  const url = `/admin/category/${id}`;
  return axiosClient.put(url, { name, description, status });
};



export {
  deletePost,
  getPostById,
  updatePostById,
  getListAllPost,
  addNewPost,
  getListAllCategory,
  addNewCategory,
  deleteCategory,
  getCategoryById,
  updateCategoryById
};
