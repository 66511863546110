import React from "react";

const ErrorAlert = ({ errors }) => {
  return (
    <div className="alert alert-danger">
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorAlert;
