import React, { useState, useEffect } from "react";

import classNames from "classnames/bind";
import styles from "page/Application/Home/Home.module.scss";
import {
  Button,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
} from "react-bootstrap";
import { getListAllCategory } from "api/app/category.api";
import ListProductByCategory from "./ListProductByCategory";

const cx = classNames.bind(styles);

function Home() {
  const [listCategory, setListCategory] = useState([]);

  const fetchDataCategory = async () => {
    const response = await getListAllCategory();
    setListCategory(response);
  };
  useEffect(() => {
    fetchDataCategory();
  }, []);
  return (
    <div className={cx("index-container")}>
      <Container className={cx("body-container")}>
        <Row className={cx("home-row-header")}>
          <Col sm={1}>
            <Button className={cx("button-search")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10.5 17.5c1.57 0 3.02-.53 4.18-1.4l4.11 4.11 1.41-1.41-4.11-4.11a7 7 0 1 0-12.6-4.18 7 7 0 0 0 7.01 6.99Zm0-12a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z"
                ></path>
              </svg>
            </Button>
          </Col>
          <Col sm={11}>
            <Nav variant="underline">
              {listCategory.map((category) => (
                <NavItem key={category.id} className={cx("category-item")}>
                  <NavLink
                    eventKey={`#${category.id}`}
                    href={`#${category.id}`}
                  >
                    {category.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
        <Row className={cx("home-row-content")}>
          <Col>
            {listCategory.map((category) => (
              <ListProductByCategory category={category} />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
