import React, { useState, useEffect } from "react";
import { Button, Table, Pagination } from "react-bootstrap";
import { getListAllCategory, deleteCategory } from "api/admin/post.api";
import { Link } from "react-router-dom";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import { toast } from "react-toastify";
import { ADMIN_PATH, ADMIN_CATEGORY_NEW_PATH } from "constant/authPath";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const AdminCategory = () => {
  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const urlAddNewCategory = `${ADMIN_PATH}/${ADMIN_CATEGORY_NEW_PATH}`;

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Thêm state để lưu thông tin về mục muốn xóa

  useEffect(() => {
    const fetchData = async () => {
      const response = await getListAllCategory(perPage, page);
      setCategory(response);
    };

    fetchData();
  }, [perPage, page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getVisibleCategory = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return category.slice(startIndex, endIndex);
  };

  // Function xác nhận xóa
  const handleDeleteConfirm = async () => {
    try {
      // Gọi API xóa với id của mục cần xóa
      await deleteCategory(itemToDelete);
      toast.success("Thực hiện thành công");
      setShowDeleteConfirm(false); // Ẩn modal
      // Cập nhật lại danh sách sau khi xóa
      const updatedList = category.filter((item) => item.id !== itemToDelete);
      setCategory(updatedList);
    } catch (error) {
      toast.error("Thực hiện xóa thất bại và có lỗi: ".error.message);
    }
  };

  // Hủy xác nhận xóa mục
  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false); // Ẩn modal
    setItemToDelete(null); // Đặt lại itemToDelete về null
  };

  // Xác định mục muốn xóa và hiển thị modal xác nhận xóa
  const handleDelete = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  return (
    <div className={cx("page-content")}>
      <div
        className={cx(
          "aiz-titlebar",
          "text-left",
          "mt-2",
          "pb-2",
          "px-3",
          "px-md-2rem",
          "border-bottom",
          "border-gray"
        )}
      >
        <div className={cx("row", "align-items-center")}>
          <div className={cx("col-md-6")}>
            <h1 className={cx("h3")}>All Category</h1>
          </div>
          <div className={cx("col-md-6", "text-md-right")}>
            <Link
              className={cx("btn", "btn-circle", "btn-info")}
              to={urlAddNewCategory}
            >
              <span>Add new Category</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={cx("card")}>
        <div className={cx("card-header", "d-block", "d-md-flex")}>
          <h5 className={cx("mb-0", "h6")}>Category</h5>
        </div>
        <div className={cx("card-body")}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Update date</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {getVisibleCategory().map((data) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.name}</td>
                  <td>{data.description}</td>
                  <td>{data.status_text}</td>
                  <td>{data.updated_at}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(data.id)}
                    >
                      Delete
                    </Button>
                    {showDeleteConfirm && (
                      <ConfirmDelete
                        show={showDeleteConfirm}
                        onCancel={handleDeleteCancel}
                        onConfirm={handleDeleteConfirm}
                        title="Delete Confirmation"
                        message="Are you sure to delete this?"
                      />
                    )}{" "}
                    <Button
                      variant="primary"
                      size="sm"
                      as={Link}
                      to={`/admin/category/edit/${data.id}`}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev onClick={() => handlePageChange(page - 1)} />
            {/* Loop to render page items */}
            {Array.from(
              { length: Math.ceil(category.length / perPage) },
              (_, i) => (
                <Pagination.Item
                  key={i + 1} // Add a unique key for each item
                  active={page === i + 1}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              )
            )}
            <Pagination.Next onClick={() => handlePageChange(page + 1)} />
            <Pagination.Last
              onClick={() =>
                handlePageChange(Math.ceil(category.length / perPage))
              }
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default AdminCategory;
