import axiosClient from "api/config/axiosClient";

const addNewAlbum = (payloadData) => {
  const url = "admin/albums";
  return axiosClient.post(url, { payloadData });
};

const updateAlbum = (id, payloadData) => {
  const url = `/admin/albums/${id}`;
  return axiosClient.put(url, { payloadData });
};

const getListAllAlbum = (perPage, page) => {
  const url = "admin/albums";
  return axiosClient.get(url, {
    perPage,
    page,
  });
};

const getAlbumById = (id) => {
  const url = `/admin/albums/${id}`;
  return axiosClient.get(url);
};

const deleteAlbum = (id) => {
  const url = `/admin/albums/${id}`;
  return axiosClient.delete(url);
};

export { deleteAlbum, getListAllAlbum, updateAlbum, addNewAlbum, getAlbumById };
