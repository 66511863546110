import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useActiveLink = (path) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(location.pathname === path);
  }, [location.pathname, path]);

  return isActive;
};

export default useActiveLink;
