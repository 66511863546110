import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Pagination,
} from "react-bootstrap";
import { getListAllPost, deletePost } from "api/admin/post.api";
import { Link } from "react-router-dom";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import { toast } from 'react-toastify';

const AdminPost = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Thêm state để lưu thông tin về mục muốn xóa

  useEffect(() => {
    // Lấy dữ liệu user từ API hoặc mock data
    const fetchData = async () => {
      const response = await getListAllPost(perPage, page);
      setPosts(response);

      console.log("check data", response);
    };

    fetchData();
  }, []);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getVisiblePosts = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return posts.slice(startIndex, endIndex);
  };

  // Function xác nhận xóa
  const handleDeleteConfirm = async () => {
    try {
      // Gọi API xóa với id của mục cần xóa
      await deletePost(itemToDelete);
      toast.success('Thực hiện thành công');
      setShowDeleteConfirm(false); // Ẩn modal
      // Cập nhật lại danh sách sau khi xóa
      const updatedList = posts.filter(item => item.id !== itemToDelete);
      setPosts(updatedList);
    } catch (error) {
      toast.error('Thực hiện xóa thất bại và có lỗi: '.error.message);
    }
  };

  // Hủy xác nhận xóa mục
  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false); // Ẩn modal
    setItemToDelete(null); // Đặt lại itemToDelete về null
  };

  // Xác định mục muốn xóa và hiển thị modal xác nhận xóa
  const handleDelete = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  return (
    <Container fluid>
      <Row className="flex-">
        <Col sm={6}>
          <h2>Danh sách bài viết</h2>
        </Col>
        <Col sm={6} className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            size="lg"
            type="button"
            as={Link}
            to="/admin/post/new"
          >
            Thêm mới bài viết
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  {/* <th>Nội dung</th> */}
                  <th>Trạng thái</th>
                  <th>Tác giả</th>
                  <th>danh mục</th>
                  <th>Cập nhật gần nhất</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                {getVisiblePosts().map((post) => (
                  <tr key={post.id}>
                    <td>{post.id}</td>
                    <td>{post.title}</td>
                    {/* <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: encodeURIComponent(post.content),
                        }}
                      ></div>
                    </td> */}
                    <td>{post.status_text}</td>
                    <td>{post.user.username}</td>
                    <td>...</td>
                    <td>{post.updated_at}</td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(post.id)}
                      >Xóa</Button>
                      {showDeleteConfirm && (
                        <ConfirmDelete
                          show={showDeleteConfirm}
                          onCancel={handleDeleteCancel}
                          onConfirm={handleDeleteConfirm}
                          title="Xác nhận xóa"
                          message="Bạn có chắc chắn muốn xóa?"
                        />
                      )}
                      {" "}
                      <Button
                        variant="primary"
                        size="lg"
                        as={Link} // Sử dụng thẻ <Link> thay vì <a> để chuyển hướng
                        to={`/admin/post/edit/${post.id}`} // Đặt đường dẫn đến màn hình chỉnh sửa và truyền ID
                      >
                        Chỉnh sửa
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination>
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev onClick={() => handlePageChange(page - 1)} />
              {/* Loop to render page items */}
              {Array.from({ length: Math.ceil(posts.length / perPage) }, (_, i) => (
                <Pagination.Item
                  key={i + 1} // Add a unique key for each item
                  active={page === (i + 1)}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => handlePageChange(page + 1)} />
              <Pagination.Last
                onClick={() =>
                  handlePageChange(Math.ceil(posts.length / perPage))
                }
              />
            </Pagination>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminPost;
