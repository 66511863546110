import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import useUserInfo from "hook/useUserInfo";
import { SIGN_IN_PATH } from "constant/appPath";
import { ADMIN_PATH } from "constant/authPath";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

const AdminHeader = () => {
  const name = "Hello, " + useUserInfo();

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    window.location.href = SIGN_IN_PATH;
  };

  return (
    <div
      className={cx(
        "d-flex",
        "justify-content-between",
        "align-items-stretch",
        "flex-grow-1"
      )}
    >
      <div
        className={cx(
          "d-flex",
          "justify-content-around",
          "align-items-center",
          "align-items-stretch"
        )}
      >
        <div className={cx("aiz-topbar-item", "mr-3")}>
          <div className={cx("d-flex", "align-items-center")}>
            <Link
              to="/"
              datatype="Browse Website"
              className={cx(
                "btn",
                "btn-topbar",
                "has-transition",
                "btn-icon",
                "btn-circle",
                "btn-light",
                "p-0",
                "hov-bg-primary",
                "hov-svg-white",
                "d-flex",
                "align-items-center",
                "justify-content-center"
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  id="_754bac7463b8b1afad8e10a2355d1700"
                  data-name="754bac7463b8b1afad8e10a2355d1700"
                  d="M56,48a8,8,0,1,0,8,8A8,8,0,0,0,56,48Zm-.829,14.808a6.858,6.858,0,0,1-4.39-11.256,7.6,7.6,0,0,1,.077.93,2.966,2.966,0,0,0,.382,2.26,3.729,3.729,0,0,1,.362,1.08c.1.341.5.519.77.729.552.423,1.081.916,1.666,1.288.387.246.628.368.515.84a2.98,2.98,0,0,1-.313.951,1.927,1.927,0,0,0,.321.861c.288.288.575.553.889.813C55.938,61.706,55.4,62.229,55.171,62.808Zm5.678-1.959a6.808,6.808,0,0,1-3.56,1.888,2.844,2.844,0,0,1,.842-1.129,2.865,2.865,0,0,0,.757-.937,6.506,6.506,0,0,1,.522-.893c.272-.419-.67-1.051-.975-1.184a10.052,10.052,0,0,1-1.814-1.13c-.435-.306-1.318.16-1.808-.054A9.462,9.462,0,0,1,53,56.166c-.6-.454-.574-.984-.574-1.654.472.017,1.144-.131,1.458.249.1.12.439.655.667.465.186-.155-.138-.779-.2-.925-.193-.451.439-.626.762-.932.422-.4,1.326-1.024,1.254-1.309s-.9-1.1-1.394-.969c-.073.019-.719.7-.844.8q0-.332.01-.663c0-.14-.26-.283-.248-.373.031-.227.664-.64.821-.821-.11-.069-.487-.392-.6-.345-.276.115-.588.194-.863.309a1.756,1.756,0,0,0-.025-.274,6.792,6.792,0,0,1,1.743-.506l.542.218.382.454.382.394.334.108.53-.5L57,49.536v-.321a6.782,6.782,0,0,1,2.9,1.146c-.155.014-.326.037-.518.061a1.723,1.723,0,0,0-.268-.1c.251.54.513,1.073.779,1.606.284.569.915,1.18,1.026,1.781.131.708.04,1.352.111,2.185a3.732,3.732,0,0,0,.9,1.714,1.812,1.812,0,0,0,.707.086A6.815,6.815,0,0,1,60.849,60.849Z"
                  transform="translate(-48 -48)"
                  fill="#717580"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
        {/* <div className={cx("aiz-topbar-item", "mr-2", "d-none", "d-xl-block")}>
          <div className={cx("d-flex", "align-items-center", "h-100")}>
            <Link
              className={cx(
                "aiz-topbar-menu",
                "fs-13",
                "fw-600",
                "d-flex",
                "align-items-center",
                "justify-content-center",
                "active"
              )}
              to={ADMIN_PATH}
            >
              Dashboard
            </Link>
            <Link
              className={cx(
                "aiz-topbar-menu",
                "fs-13",
                "fw-600",
                "d-flex",
                "align-items-center",
                "justify-content-center",
                ""
              )}
              to={ADMIN_PATH}
            >
              Order
            </Link>
            <Link
              className={cx(
                "aiz-topbar-menu",
                "fs-13",
                "fw-600",
                "d-flex",
                "align-items-center",
                "justify-content-center",
                ""
              )}
              to={ADMIN_PATH}
            >
              Homepage Setting
            </Link>
          </div>
        </div> */}
      </div>
      <div
        className={cx(
          "d-flex",
          "justify-content-around",
          "align-items-center",
          "align-items-stretch"
        )}
      >
        <div className={cx("aiz-topbar-item")}>
          <div className={cx("align-items-stretch", "d-flex", "dropdown")}>
            <Navbar>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <NavDropdown
                  title={name}
                  id="basic-nav-dropdown"
                  className={cx("header-info-user")}
                >
                  <NavDropdown.Item onClick={handleLogout}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
