import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    Row,
    Col,
    Table,
    Pagination,
} from "react-bootstrap";
import { getListPageAttribute } from "api/admin/attribute.api";
import { Link } from "react-router-dom";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import { ADMIN_ATTRIBUE_NEW_PATH, ADMIN_PATH, ADMIN_ATTRIBUE_EDIT_PATH } from "constant/authPath";

const AdminAttributes = () => {
    const [listData, setListData] = useState([]);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [perPage, setPerPage] = useState(PAGE_SIZE);
    const urlAddNew = `${ADMIN_PATH}/${ADMIN_ATTRIBUE_NEW_PATH}`;

    useEffect(() => {
        const fetchData = async () => {
            const response = await getListPageAttribute(perPage, page);
            setListData(response);
            console.log("Check response: ", response);
        };

        fetchData();
    }, []);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const getListData = () => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        return listData.slice(startIndex, endIndex);
    };

    // Update User
    const handleEdit = (id) => {
        console.log("User ID update: ", id);
    };
    return (
        <Container fluid>
            <Row className="flex-">
                <Col sm={6}>
                    <h2>List All Attribute</h2>
                </Col>
                <Col sm={6} className="d-flex justify-content-end">
                    <Button
                        variant="outline-primary"
                        size="lg"
                        type="button"
                        as={Link}
                        to={urlAddNew}
                    >
                        Add new ATTRIBUTE
                    </Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Attribute Name</th>
                                    <th>Option display</th>
                                    <th>Status</th>
                                    <th>Update At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getListData().map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.id}</td>
                                        <td>{data.name}</td>
                                        <td>{data.option_display === 1 ? "Pulldown" : "Checkbox"}</td>
                                        <td>{data.delete_flag_text}</td>
                                        <td>{data.updated_at}</td>
                                        <td>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                as={Link} // Sử dụng thẻ <Link> thay vì <a> để chuyển hướng
                                                to={`${ADMIN_PATH}/attributes/edit/${data.id}`}
                                            >
                                                Edit
                                            </Button>
                                            {" "}
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                as={Link} // Sử dụng thẻ <Link> thay vì <a> để chuyển hướng
                                                to={`${ADMIN_PATH}/attributes/${data.id}/items`}
                                            >
                                                Add Item
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} />
                            <Pagination.Prev onClick={() => handlePageChange(page - 1)} />
                            {/* Loop to render page items */}
                            {Array.from({ length: Math.ceil(listData.length / perPage) }, (_, i) => (
                                <Pagination.Item
                                    key={i + 1} // Add a unique key for each item
                                    active={page === (i + 1)}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(page + 1)} />
                            <Pagination.Last
                                onClick={() =>
                                    handlePageChange(Math.ceil(listData.length / perPage))
                                }
                            />
                        </Pagination>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminAttributes;
