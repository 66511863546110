import React, { useEffect, useState } from "react";
import { CheckboxInput } from "components/FormAdmin";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import { getListAllCategory } from "api/admin/post.api";
import { getListCategoryByProductId } from "api/admin/product.api";

const cx = classNames.bind(styles);

const ProductCategory = ({ onChange, productId }) => {
  const [categories, setCategories] = useState([]);

  const fetchData = async () => {
    let allCategories = await getListAllCategory(1000, 1);
    let productCategories = [];

    if (productId) {
      productCategories = await getListCategoryByProductId(productId);
    }

    // Add a selected property to each category
    const categoriesWithSelection = allCategories.map((category) => ({
      ...category,
      selected: productCategories.includes(category.id),
    }));

    setCategories(categoriesWithSelection);

    // Pass the initially selected category IDs to the parent component
    const selectedCategoryIds = categoriesWithSelection
      .filter((category) => category.selected)
      .map((category) => category.id);

    onChange(selectedCategoryIds);
  };

  useEffect(() => {
    fetchData();
  }, [productId]);

  // Function to handle category selection/deselection
  const handleCategorySelection = (categoryId) => {
    const updatedCategories = categories.map((category) =>
      category.id === categoryId
        ? { ...category, selected: !category.selected }
        : category
    );

    setCategories(updatedCategories);

    // Filter out only selected category IDs
    const selectedCategoryIds = updatedCategories
      .filter((category) => category.selected)
      .map((category) => category.id);

    // Pass the updated selected category IDs to the parent component
    onChange(selectedCategoryIds);
  };

  return (
    <div className={cx("card")}>
      <div className={cx("card-header")}>
        <h5 className={cx("mb-0", "h6")}>Product category</h5>
        <h6 className={cx("float-right", "fs-13", "mb-0")}>
          Select Main
          <span className={cx("position-relative", "main-category-info-icon")}>
            <i
              className={cx("las", "la-question-circle", "fs-18", "text-info")}
            ></i>
            {/* Info tooltip (optional) */}
          </span>
        </h6>
      </div>
      <div className={cx("card-body")}>
        <div className={cx("h-300px", "overflow-auto", "c-scrollbar-light")}>
          <div id="treeview_container" className={cx("hummingbird-treeview")}>
            <ul id="treeview" className={cx("hummingbird-base")}>
              {categories.map((category) => (
                <li key={category.id}>
                  {/* Pass handleCategorySelection function to CheckboxInput */}
                  <CheckboxInput
                    id={category.id}
                    name={category.name}
                    checked={category.selected}
                    onChange={() => handleCategorySelection(category.id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
