import React from 'react';
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const InputTextArea = ({
    label,
    id,
    value,
    onChange,
}) => {
    return (
        <div className={cx("form-group", "row")}>
            <label htmlFor={id} className={cx("col-md-3", "col-form-label")}>
                {label}
            </label>
            <div className={cx("col-md-9")}>
                <textarea value={value} onChange={onChange} className={cx("form-control")} rows={6} />
            </div>
        </div>
    );
};

export default InputTextArea;
