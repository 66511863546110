import { atom, selector } from "recoil";
import { localStorageEffect } from "recoil/localStorage/localStorageEffect";

// Atom để lưu trữ danh sách sản phẩm trong giỏ hàng
export const cartItemsState = atom({
  key: "cartItemsState",
  default: [],
  effects: [localStorageEffect("cartItems")],
});

// Selector để tính tổng số sản phẩm trong giỏ hàng
export const cartItemCountSelector = selector({
  key: "cartItemCountSelector",
  get: ({ get }) => {
    const cartItems = get(cartItemsState);
    return cartItems.length;
  },
});

// Selector để tính tổng giá trị của giỏ hàng
export const cartTotalPriceSelector = selector({
  key: "cartTotalPriceSelector",
  get: ({ get }) => {
    const cartItems = get(cartItemsState);
    return cartItems.reduce((total, item) => total + item.price, 0);
  },
});
