import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { getListAllAttribute } from "api/admin/attribute.api";

const theme = {
  container: "react-autosuggest__container position-relative form-group",
  containerOpen: "react-autosuggest__container--open",
  input: "react-autosuggest__input form-control",
  inputOpen: "react-autosuggest__input--open",
  inputFocused: "react-autosuggest__input--focused",
  suggestionsContainer:
    "react-autosuggest__suggestions-container position-absolute",
  suggestionsContainerOpen: "react-autosuggest__suggestions-container--open",
  suggestionsList: "react-autosuggest__suggestions-list list-group",
  suggestion: "react-autosuggest__suggestion list-group-item",
  suggestionFirst: "react-autosuggest__suggestion--first",
  suggestionHighlighted: "react-autosuggest__suggestion--highlighted active",
  sectionContainer: "react-autosuggest__section-container",
  sectionContainerFirst: "react-autosuggest__section-container--first",
  sectionTitle: "react-autosuggest__section-title",
};

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, data) => {
  if (typeof value !== "string") {
    return [];
  }

  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (data.length === 0) {
    return [];
  }

  return inputLength === 0
    ? []
    : data.filter(
        (item) => item.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => {
  return suggestion.name;
};

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

const InputTextSearchAttribute = ({ onSelect }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const fetchedData = await getListAllAttribute();
    setData(fetchedData);
  };

  const handleOnChange = (e, { newValue = "" }) => {
    if (e && e.target) {
      setValue(newValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value !== undefined) {
      setSuggestions(getSuggestions(value, data));
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (
    e,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    onSelect(suggestion);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const inputProps = {
    placeholder: "Select ingredients",
    value,
    onChange: handleOnChange, // Chỉ truyền hàm handleOnChange mà không gọi nó ngay lúc này
  };

  return (
    <Autosuggest
      theme={theme}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      highlightFirstSuggestion={true}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};

export default InputTextSearchAttribute;
