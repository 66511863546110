import BarChart from "components/BarChart/BarChart";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Dashboard = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm={10}>Dashboard</Col>
        <Col sm={2}>Thông báo</Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
