import React from "react";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const InputPulldown = ({ label, id, name, value, options, onChange }) => {
  return (
    <div className={cx("form-group", "row")}>
      <label htmlFor={id} className={cx("col-md-3", "col-form-label")}>
        {label}
      </label>
      <div className={cx("col-md-9")}>
        <select
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={cx("form-control")}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default InputPulldown;
