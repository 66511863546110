import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "page/Application/Cart/Cart.module.scss";
import ListItemOrder from "layouts/components/ListItemOrder";

const cx = classNames.bind(styles);

const OrderSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { order, orderDetails, paymentMethod } = location.state;

    useEffect(() => {
        if (paymentMethod === 2) {

            const redirectTimeout = setTimeout(() => {
                // Chuyển hướng sau 3 giây
                window.location.href = 'https://www.clover.com/online-ordering/drip-drip-coffeehouse-seattle';
            }, 3000); // 3000 là số mili giây tương ứng với 3 giây

            // Xóa timeout khi component unmount để tránh memory leak
            return () => clearTimeout(redirectTimeout);
        }
    }, [navigate, paymentMethod]); // Chỉ chạy lại useEffect khi navigate thay đổi
    return (
        <div className={cx("index-container")}>
            <Container className={cx("body-container")}>
                <Row className={cx("row")}>
                    <Col className={cx("title-cart-page")}>Order Placed Successfully!</Col>
                </Row>
                <Row className={cx("row-delivery-address")}>
                    <Col>
                        <h2 className={cx("delivery-address-title")}>
                            Delivery Address
                        </h2>
                        <div className={cx("delivery-address-item")}>Shipping Address: {order.shipping_address}</div>
                        <div className={cx("delivery-address-item")}>Phone: {order.phipping_phone}</div>
                        <div className={cx("delivery-address-item")}>Note: {order.phipping_note}</div>
                    </Col>
                </Row>
                <Row className={cx("row-delivery-address")}>
                    <Col>
                        <h2 className={cx("delivery-address-title")}>
                            Billing Information
                        </h2>
                        <div className={cx("delivery-address-item")}>Total Amount: ${order.total_amount}</div>
                        <div className={cx("delivery-address-item")}>
                            {paymentMethod === 1 && (
                                <span>Payment: Cash upon delivery</span>
                            )}
                            {paymentMethod === 2 && (
                                <span>Payment: Pay at <Link to="https://www.clover.com/online-ordering/drip-drip-coffeehouse-seattle">Clover</Link></span>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row className={cx("row-delivery-address")}>
                    <Col>
                        <h2 className={cx("delivery-address-title")}>
                            Ordered Items
                        </h2>
                        <ListItemOrder orderDetails={orderDetails} />
                    </Col>
                </Row>
                <Row className={cx("order-success-row-paymen")}>
                    <Col>
                        <Button className={cx("btn-paymen")} href="/">Back to Shopping</Button>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default OrderSuccess;
