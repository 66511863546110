import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "layouts/components/ListItemOrder/ListItemOrder.module.scss";

const cx = classNames.bind(styles);

const ListItemOrder = ({ orderDetails }) => {
    console.log("Check orderDetails: ", orderDetails);
    return (
        <div className={cx("order-index")}>
            {
                orderDetails.map((item) => (
                    <Container className={cx("order-containner")}>
                        <Row className={cx("order-row")}>
                            <Col md={2} className={cx("order-col")}>
                                <Card.Img
                                    variant="top"
                                    src={item.product.thumbnail}
                                    alt={item.product.product_name}
                                    className={cx("order-col-img")}
                                />
                            </Col>
                            <Col md={7} className={cx("order-col")}>
                                <Card.Body>
                                    <Card.Title className={cx("order-col-item-text", "font-weight-600")}>
                                        {item.product.product_name}
                                    </Card.Title>
                                    <Card.Text className={cx("order-col-item-text", "font-weight-300")}>
                                        {item.product.information}
                                    </Card.Text>
                                    <Card.Text className={cx("order-col-item-text", "font-weight-400")}>
                                        {" "}
                                    </Card.Text>
                                    <Card.Text className={cx("order-col-item-text", "font-weight-400")}>
                                        Quantity: {item.quantity}
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                            <Col md={3} className={cx("cart-item-info")}>
                                <Card.Body>
                                    <Card.Text className={cx("order-col-item-text", "font-weight-400")}>
                                        ${item.product.cost_price}
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                        </Row>
                        <Row className={cx("cart-hr")}></Row>
                    </Container>
                ))
            }
        </div>

    );
}

export default ListItemOrder;