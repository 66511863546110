import PropTypes from 'prop-types';
import './GlobalAppStyle.scss';

function GlobalAppStyle({ children }) {
    return children;
}

GlobalAppStyle.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GlobalAppStyle;
