import React from "react";
import classNames from "classnames/bind";
import styles from "layouts/components/Header/Header.module.scss";
import NavHeader from "./NavHeader";
import MainHeader from "./MainHeader";

const cx = classNames.bind(styles);

function Header() {
  return (
    <div className={cx("w-block-background")}>
      <div className={cx("w-header", "header-6", "container", "header-content", "content-align--center")}>
        <div className={cx("w-container", "header__content-container", "col")}>
          <MainHeader />
          <NavHeader />
        </div>
      </div>
      <div className={cx("w-wrapper", "header__fulfillment", "container")}>
        <div className={cx("header-noti")}>
          <span className={cx("header-noti-text")}>Pickup from 3801 Stone Way N Suite C</span>
        </div>
      </div>
    </div>
  );
}

export default Header;
