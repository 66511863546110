import React from "react";
import { Outlet } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";

import AdminHeader from "layouts/AdminLayout/AdminHeader";
import LeftSidebarMenu from "layouts/AdminLayout/LeftSidebarMenu";

const cx = classNames.bind(styles);

function Admin() {
  return (
    <div className={cx("aiz-main-wrapper")}>
      <div className={cx("aiz-sidebar-wrap")}>
        <LeftSidebarMenu />
        <div className={cx("aiz-sidebar-overlay")}></div>
      </div>
      <div className={cx("aiz-content-wrapper", "bg-white")}>
        <div
          className={cx(
            "aiz-topbar",
            "px-15px",
            "px-lg-25px",
            "d-flex",
            "align-items-stretch",
            "justify-content-between"
          )}
        >
          <AdminHeader />
        </div>
        <div className={cx("aiz-main-content")}>
          <div className={cx("px-15px", "px-lg-25px")}>
            <Outlet />
          </div>
          <div
            className={cx(
              "bg-white",
              "text-center",
              "py-3",
              "px-15px",
              "px-lg-25px",
              "mt-auto",
              "border-top"
            )}
          >
            <p className={cx("mb-0")}>Copyright &copy; 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
