import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames/bind";
import styles from "layouts/DefaultLayout/DefaultLayout.module.scss";
import { profile } from "api/auth/authen.api";

const cx = classNames.bind(styles);

const IntroductoryDiagram = () => {
  const [user, setUser] = useState([]);
  const [error, setError] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const response = await profile();
      setUser(response);
    } catch (error) {
      // Xử lý lỗi đăng nhập
      setError(error.response.data.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={cx("col-right-account", "border-0", "margin-top-20")}>
      <div className={cx("block-account")} style={{ paddingTop: "35px" }}>
        <div className={cx("block-title-account")}>
          <h1>Sơ đồ giới thiệu</h1>
        </div>
        <div className={cx("block-content", "form-signup")}></div>
      </div>
    </div>
  );
};
export default IntroductoryDiagram;
