import React, { useState, useEffect } from "react";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import { addNewAlbum, getAlbumById, updateAlbum } from "api/admin/album.api";
import { addNewImage } from "api/admin/image.api";
import { ADMIN_PATH, ADMIN_ALBUM_PATH } from "constant/authPath";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { InputText, InputTextArea, InputPulldown } from "components/FormAdmin";

const cx = classNames.bind(styles);

const albumList = [
  { value: "1", label: "Album slide" },
  { value: "2", label: "Album image our story" },
];

const AdminAlbumNew = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [albumId, setAlbumId] = useState("1");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await addNewImage(formData);
      setThumbnail(response.path);
      toast.success("upload image success!");
    } catch (error) {
      toast.error("Error uploading image:" + error.message);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAlbumById(id);
      setName(response.product_name);
      setDescription(response.information);
      setThumbnail(response.thumbnail);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { id } = useParams(); // Lấy ID từ URL
  const isEditMode = !!id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payloadData = {
      image_name: name,
      image_url: thumbnail,
      caption: description,
      album_id: albumId
    };
    try {
      if (isEditMode) {
        const response = await updateAlbum(id, payloadData);
        toast.success("Update product success!");
        window.location.href = ADMIN_PATH + "/" + ADMIN_ALBUM_PATH;
      } else {
        const response = await addNewAlbum(payloadData);
        toast.success("Add new product success!");
        window.location.href = ADMIN_PATH + "/" + ADMIN_ALBUM_PATH;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={cx("page-content")}>
      <div
        className={cx(
          "aiz-titlebar",
          "text-left",
          "mt-2",
          "pb-2",
          "px-3",
          "px-md-2rem",
          "border-bottom",
          "border-gray"
        )}
      >
        <div className={cx("row", "align-items-center")}>
          <div className={cx("col-md-6")}>
            <h1 className={cx("h3")}>
              {isEditMode ? "Edit Image" : "Add new Image"}
            </h1>
          </div>
          <div className={cx("col-md-6", "text-md-right")}></div>
        </div>
      </div>
      <div className={cx("d-sm-flex")}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="general">
          <div
            className={cx("page-side-nav", "c-scrollbar-light", "px-3", "py-2")}
          >
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="general">General</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div
            className={cx(
              "flex-grow-1",
              "p-sm-3",
              "p-lg-2rem",
              "mb-2rem",
              "mb-md-0"
            )}
          >
            <form onSubmit={handleSubmit}>
              {/* Tab content: Product Information */}
              <Tab.Content>
                <Tab.Pane eventKey="general" className={cx("tab-pane", "fade")}>
                  <h5
                    className={cx("mb-3", "pb-3", "fs-17", "fw-700")}
                    style={{ borderBottom: "1px dashed #e4e5eb" }}
                  >
                    Image Information
                  </h5>
                  <div className={cx("w-100")}>
                    <div className={cx("row")}>
                      <div className={cx("col-xxl-7", "col-xl-6")}>
                        <InputText
                          label="Image name"
                          id="name"
                          value={name}
                          placeholder="Enter a image name"
                          maxLength={255}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <InputTextArea
                          label="Caption"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <InputPulldown
                          label="Album category"
                          id="albumId"
                          name="albumId"
                          value={albumId}
                          options={albumList}
                          onChange={(e) => setAlbumId(e.target.value)}
                        />
                        <div className={cx("form-group", "row")}>
                          <label
                            htmlFor={id}
                            className={cx("col-md-3", "col-form-label")}
                          >
                            Thumbnail
                          </label>
                          <div className={cx("col-md-9")}>
                            <input type="file" onChange={handleFileChange} />
                            <div onClick={handleUpload}>Upload</div>
                            {thumbnail && (
                              <div>
                                <img
                                  src={thumbnail}
                                  alt="Uploaded"
                                  style={{ maxWidth: "100%" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={cx("mt-4", "text-right")}>
                      <button
                        type="submit"
                        name="button"
                        value="publish"
                        class={cx(
                          "mx-2",
                          "btn",
                          "btn-success",
                          "w-230px",
                          "btn-md",
                          "rounded-2",
                          "fs-14",
                          "fw-700",
                          "shadow-success",
                          "action-btn"
                        )}
                      >
                        Save &amp; Publish
                      </button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </form>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AdminAlbumNew;
