import React from 'react';
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const InputText = ({
    label,
    type = 'text',
    id,
    name,
    value,
    placeholder,
    onChange,
    maxLength = 65535,
    ...rest
}) => {
    return (
        <div className={cx("form-group", "row")}>
            <label htmlFor={id} className={cx("col-md-3", "col-form-label")}>
                {label}
            </label>
            <div className={cx("col-md-9")}>
                <input
                    type={type}
                    id={id}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    className={cx("form-control")}
                    {...rest}
                />
                {value && value.length > maxLength && <span className="text-danger">{maxLength} character limit exceeded!</span>}
            </div>
        </div>
    );
};

export default InputText;