// authentication router
const SIGN_IN_PATH = "/auth/";
const SIGN_UP_PATH = "register";

const PAGE_404_PATH = "/error";

const HOMEPAGE = "/";
const CART_PATH = "/cart";
const CHECKOUT_PATH = "/checkout";
const ORDER_SUCCESS_PATH = "/order-success";
const HOME_PAGE_TOP = "/home";
const GIFT_CARDS_PAGE = "/gift-cards";
const CONTACT_US_PAGE = "/contact-us";

export {
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    HOMEPAGE,
    PAGE_404_PATH,
    CART_PATH,
    CHECKOUT_PATH,
    ORDER_SUCCESS_PATH,
    HOME_PAGE_TOP,
    GIFT_CARDS_PAGE,
    CONTACT_US_PAGE
};
