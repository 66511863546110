import React, { useState } from "react";

import classNames from "classnames/bind";
import styles from "layouts/components/Header/MainHeader/MainHeader.module.scss";
import logo from "assets/img/header/logo.webp";
import { Link } from "react-router-dom";
import { SIGN_IN_PATH, CART_PATH, HOMEPAGE } from "constant/appPath";
import { USER_PATH } from "constant/authPath";
import useCheckRouterValid from "hook/useCheckRouterValid";
import { Col, Container, Row } from "react-bootstrap";
import { useRecoilValue, useRecoilState } from "recoil";
import { cartItemCountSelector, cartItemsState } from "recoil/atom/cart";
import { Card } from "react-bootstrap";

const cx = classNames.bind(styles);

const MainHeader = () => {
  const [showCartInfo, setShowCartInfo] = useState(false);
  const checkLogin = useCheckRouterValid();
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    window.location.href = HOMEPAGE;
  };

  // Hàm xử lý xóa sản phẩm khỏi giỏ hàng
  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter(
      (item) => item.product.id !== productId
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  // Tính tổng số lượng sản phẩm trong giỏ hàng
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Hàm tính tổng tiền giỏ hàng
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.totalPrice; // Tính tổng tiền của từng sản phẩm và cộng vào total
    });
    return total;
  };

  // Hàm xử lý khi di chuột vào biểu tượng giỏ hàng
  const handleMouseEnter = () => {
    setShowCartInfo(true);
  };

  // Hàm xử lý khi di chuột ra khỏi biểu tượng giỏ hàng
  const handleMouseLeave = () => {
    setShowCartInfo(false);
  };

  const cartItemCount = useRecoilValue(cartItemCountSelector);

  return (
    <div className={cx("w-cell", "header__top", "row")}>
      <Container>
        <Row style={{ width: "100%" }}>
          <Col sm={5} xs={0}></Col>
          <Col sm={2} xs={4} className={cx("header-logo")}>
            <Link to="/">
              <img src={logo} alt="logo" className={cx("img-logo")} />
            </Link>
          </Col>
          <Col sm={5} xs={8} className={cx("header__icons", "header__icons")}>
            <div
              className={cx(
                "header-animate__wrap",
                "header__button",
                "display-desktop"
              )}
            >
              <div className={cx("w-wrapper")}>
                <Link
                  type="button"
                  to="/order"
                  className={cx("button-order-now")}
                >
                  Order now
                </Link>
                {checkLogin ? (
                  <>
                    <Link to={USER_PATH} className={cx("button-login")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M14 7a2 2 0 0 0-2-2 2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2ZM8 7a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm10 14h2a8.01 8.01 0 0 0-16 0h2a6 6 0 0 1 12 0Z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                    <Link onClick={handleLogout} className={cx("button-login")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M14 5l7 7-7 7M3 12h18"></path>
                      </svg>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={SIGN_IN_PATH} className={cx("button-login")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M14 7a2 2 0 0 0-2-2 2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2ZM8 7a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm10 14h2a8.01 8.01 0 0 0-16 0h2a6 6 0 0 1 12 0Z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </>
                )}
                <div className={cx("div-cart")} onMouseLeave={handleMouseLeave}>
                  <Link
                    to={CART_PATH}
                    className={cx("button-cart")}
                    onMouseEnter={handleMouseEnter}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M7 14a1 1 0 0 0-1 1 1 1 0 0 0 1 1h13v2H7a2.98 2.98 0 0 1-1.33-5.67L4 4H2V2h6v2h12l-2 10H7Zm9.36-2 1.2-6H6.44l1.2 6h8.72ZM7.5 22a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm9 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                  {cartItemCount > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        textAlign: "center",
                        lineHeight: "20px",
                        color: "white",
                      }}
                    >
                      {cartItemCount}
                    </div>
                  )}
                  {showCartInfo && (
                    <div
                      className={cx("cart-info")}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {/* Hiển thị thông tin giỏ hàng ở đây */}
                      <div>
                        {totalItems === 0 ? (
                          <>
                            <h2 className={cx("title-cart")}>Your cart</h2>
                            <p>Your cart is empty</p>
                          </>
                        ) : (
                          <>
                            <h2 className={cx("title-cart")}>
                              Your cart ({totalItems})
                            </h2>
                            {cartItems.map((item) => (
                              <Container className={cx("cart-item")}>
                                <Row>
                                  <Col
                                    md={4}
                                    className={cx("cart-image-product")}
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={item.product.thumbnail}
                                      alt={item.product.product_name}
                                      className={cx("cart-image-product-img")}
                                    />
                                  </Col>
                                  <Col md={5} className={cx("cart-item-info")}>
                                    <Card.Body>
                                      <Card.Title
                                        className={cx("cart-item-text")}
                                      >
                                        {item.product.product_name}
                                      </Card.Title>
                                      <Card.Text
                                        className={cx("cart-item-text")}
                                      >
                                        Quantity: {item.quantity}
                                      </Card.Text>
                                      <Card.Text>
                                        <div
                                          onClick={() =>
                                            removeFromCart(item.product.id)
                                          }
                                          className={cx(
                                            "cart-item-icon-delete"
                                          )}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="currentColor"
                                              fill-rule="evenodd"
                                              d="M21 5h-6a3 3 0 0 0-3-3 3 3 0 0 0-3 3H3v2h2v11.75C5 20.54 6.66 22 8.7 22h6.6c2.04 0 3.7-1.46 3.7-3.25V7h2V5Zm-9-1a1 1 0 0 1 1 1h-2a1 1 0 0 1 1-1Zm5 14.75c0 .68-.78 1.25-1.7 1.25H8.7c-.92 0-1.7-.57-1.7-1.25V7h10v11.75ZM15 9h-2v9h2V9ZM9 9h2v9H9V9Z"
                                              clip-rule="evenodd"
                                            ></path>
                                          </svg>
                                        </div>
                                      </Card.Text>
                                    </Card.Body>
                                  </Col>
                                  <Col md={3} className={cx("cart-item-info")}>
                                    <Card.Body>
                                      <Card.Text
                                        className={cx("cart-item-text")}
                                      >
                                        ${item.totalPrice}
                                      </Card.Text>
                                    </Card.Body>
                                  </Col>
                                </Row>
                                <Row className={cx("cart-hr")}></Row>
                              </Container>
                            ))}
                            <div className="footer-cart">
                              <Link to={"/cart"} className={cx("cart-btn")}>
                                Continue to cart $ {calculateTotal()}
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainHeader;
