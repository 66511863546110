import React from "react";
import classNames from "classnames/bind";
import styles from "layouts/components/Footer/Footer.module.scss";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { HOMEPAGE } from "constant/appPath";
import SignUpForm from "components/Form/SignUpForm";

const cx = classNames.bind(styles);

function Footer() {
  return (
    <div className={cx("footer-container")}>
      <div className={cx("content-align--center")}>
        <div className={cx("footer-item")}>
          <div className={cx("w-container")}>
            <Container fluid>
              <Row>
                <Col sm={6} xs={12} className={cx("footer-top-item")}>
                  <div className={cx("footer-company-name")}>
                    <span>AROOM COFFEE</span>
                  </div>
                  <div className={cx("footer-menu")}>
                    <Link to={HOMEPAGE} className={cx("footer-menu-item")}>
                      Home
                    </Link>
                    <Link to={HOMEPAGE} className={cx("footer-menu-item")}>
                      Gift cards
                    </Link>
                    <Link to={HOMEPAGE} className={cx("footer-menu-item")}>
                      Contact Us
                    </Link>
                  </div>
                </Col>
                <Col sm={6} xs={12} className={cx("footer-top-item")}>
                  <div className={cx("footer-text")}>
                    <span>Stay in the Loop!</span>
                  </div>
                  <SignUpForm />
                  <div className={cx("fotter-text-comment")}>
                    <span>
                      This form is protected by reCAPTCHA and the Google{" "}
                      <Link to="https://policies.google.com/privacy">
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link to="https://policies.google.com/terms">
                        Terms of Service
                      </Link>{" "}
                      apply.
                    </span>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className={cx("footer-item")}>
          <div className={cx("footer-hr")}></div>
        </div>
        <div className={cx("footer-item")}>
          <div className={cx("w-container")}>
            <Container fluid>
              <Row>
                <Col sm={7} className={cx("footer-top-item")}></Col>
                <Col sm={5} className={cx("footer-top-item")}>
                  <div className={cx("footer-social-network")}>
                    Cookie Settings © 2024
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
