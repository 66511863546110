import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import { Link } from "react-router-dom";
import { menuItems } from "constant/menuAdmin";

const cx = classNames.bind(styles);

const LeftSidebarMenu = () => {
  const [menuState, setMenuState] = useState({
    activeLevel1: "dashboard",
    activeLevel2: null,
  });

  const handleToggleMenu = (level1, level2) => {
    if (
      menuState.activeLevel1 === level1 &&
      menuState.activeLevel2 === level2
    ) {
      setMenuState({ activeLevel1: null, activeLevel2: null });
    } else {
      setMenuState({ activeLevel1: level1, activeLevel2: level2 });
    }
  };

  return (
    <div className={cx("aiz-sidebar", "left", "c-scrollbar")}>
      <div className={cx("aiz-side-nav-logo-wrap")}>Logo Admin</div>
      <div className={cx("aiz-side-nav-wrap")}>
        <ul className={cx("aiz-side-nav-list", "metismenu")}>
          {menuItems.map((item) => (
            <li className={cx("aiz-side-nav-item")} key={item.id}>
              <Link
                to={item.url}
                className={cx(
                  "aiz-side-nav-link",
                  menuState.activeLevel1 === item.id ? "active" : " "
                )}
                onClick={() => handleToggleMenu(item.id, null)}
              >
                <div className={cx("aiz-side-nav-icon")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id={item.icon_menu.id}
                      data-name={item.icon_menu.data_name}
                      d={item.icon_menu.d}
                      transform="translate(-2 -2)"
                      fill="#575b6a"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <span className={cx("aiz-side-nav-text")}>{item.title}</span>
              </Link>
              {menuState.activeLevel1 === item.id && (
                <ul
                  className={cx(
                    "aiz-side-nav-list",
                    "level-2",
                    "mm-collapse",
                    "mm-show"
                  )}
                >
                  {item.subItems.map((subItem) => (
                    <li key={subItem.id} className={cx("aiz-side-nav-item")}>
                      <Link
                        to={subItem.url}
                        className={cx(
                          "aiz-side-nav-link",
                          menuState.activeLevel2 === subItem.id ? "active" : " "
                        )}
                        onClick={() => handleToggleMenu(item.id, subItem.id)}
                      >
                        <span className={cx("aiz-side-nav-text")}>
                          {subItem.title}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LeftSidebarMenu;
