import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { authenticationToken } from "recoil/atom/auth";

const useCheckRoleAdmin = () => {
    const { role } = useRecoilValue(authenticationToken);
    const checkRoleAdmin = useMemo(() => {
        if (role && role === "admin") {
            return true;
        } else {
            return false;
        }
    }, [role]);
    return checkRoleAdmin;
};

export default useCheckRoleAdmin;
