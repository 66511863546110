import React from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from "classnames/bind";
import styles from 'layouts/components/Header/NavHeader/NavHeader.module.scss';
import { Link } from 'react-router-dom';
import { GIFT_CARDS_PAGE, HOME_PAGE_TOP, CONTACT_US_PAGE } from "constant/appPath";

const cx = classNames.bind(styles);


const NavHeader = () => {
    return (
        <div className={cx("w-cell", "display-desktop", "row")}>
            <div className={cx("header-animate__wrap", "header__navigation")}>
                <div className={cx("w-wrapper")}>
                    <div className={cx("w-nav")}>
                        <ul className={cx("nav__main", "nav--uppercase")}>
                            <li className={cx("nav__item")}><Link to={HOME_PAGE_TOP}>home</Link></li>
                            <li className={cx("nav__item")}><Link to={GIFT_CARDS_PAGE}>Gift cards</Link></li>
                            <li className={cx("nav__item")}><Link to={CONTACT_US_PAGE}>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavHeader;