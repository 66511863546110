import React from "react";
import { Outlet } from "react-router-dom";
import Header from "layouts/components/Header";
import Footer from "layouts/components/Footer";
import classNames from "classnames/bind";
import styles from "page/Application/Application.module.scss";

const cx = classNames.bind(styles);

function Application() {
  return (
    <div className={cx("app-container")}>
      <div className={cx("📚19-6-9rI2oH")}>
        <div className={cx("reset-z-index")}>
          <div className={cx("w-container", "main-container", "col")}>
            <Header />
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Application;
