import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "layouts/AdminLayout/AdminLayout.module.scss";
import {
  getAttributeById,
  addAttributeItem,
  deleteAttributeItem,
} from "api/admin/attribute.api";
import { useParams, Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { InputText } from "components/FormAdmin";

const cx = classNames.bind(styles);

const AdminAttributesAddItem = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState("");
  const [itemIsFree, setItemIsFree] = useState(false);
  const [itemPrice, setItemPrice] = useState(0.00);
  const [itemOptionDisplay, setItemOptDisplay] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAttributeById(id);
      setName(response.name);
      setItems(response.items);
      setItemOptDisplay(response.option_display)
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddItem = async () => {
    if (itemName.trim() !== "") {
      try {
        await addAttributeItem(id, itemName, itemIsFree, itemPrice);
        setItemName("");
        setItemPrice(false);
        setItemPrice(0.00);
        fetchData();
        toast.success("Item added successfully.");
      } catch (error) {
        toast.error(error.response);
      }
    } else {
      alert("Please enter item name.");
    }
  };

  const removeItem = async (itemId) => {
    try {
      await deleteAttributeItem(itemId);
      fetchData();
      toast.success("Item deleted successfully.");
    } catch (error) {
      toast.error(error.response);
    }
  };

  const handleCheckIsFree = (e) => {
    setItemIsFree(!itemIsFree);
    if (!itemIsFree) {
      setItemPrice(0.00);
    }
  }

  return (
    <div className={cx("page-content")}>
      <div
        className={cx(
          "aiz-titlebar",
          "text-left",
          "mt-2",
          "pb-2",
          "px-3",
          "px-md-2rem",
          "border-bottom",
          "border-gray"
        )}
      >
        <div className={cx("row", "align-items-center")}>
          <div className={cx("col-md-6")}>
            <h1 className={cx("h3")}>
              Create components for the "{name}" property
            </h1>
            <h1 className={cx("h3")}>Option display: {itemOptionDisplay === 1 ? "Pulldown" : "Checkbox"}</h1>
          </div>
          <div className={cx("col-md-6", "text-md-right")}></div>
        </div>
      </div>
      <div className={cx("card")}>
        <div className={cx("card-header", "d-block", "d-md-flex")}>
          <h5 className={cx("mb-0", "h6")}>List Item</h5>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Item Name</th>
              <th>Item is Free</th>
              <th>Item price</th>
              <th>Update At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {items.map((data) => (
              <tr key={data.id}>
                <td>{data.id}</td>
                <td>{data.name}</td>
                <td>{data.is_free ? "True" : "Flase"}</td>
                <td>{data.price}</td>
                <td>{data.updated_at}</td>
                <td>
                  <Button variant="primary" size="sm" onClick={() => removeItem(data.id)}>
                    Delete Item
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className={cx("card-body")}>
          <InputText
            label="Item name"
            id="itemName"
            value={itemName}
            placeholder="Enter a item name"
            maxLength={255}
            onChange={(e) => setItemName(e.target.value)}
          />
          <div className={cx("form-group", "row")}>
            <label htmlFor={id} className={cx("col-md-3", "col-form-label")}>
              Item is Free
            </label>
            <div className={cx("col-md-9")}>
              <input
                className={cx("hummingbird-end-node")}
                type="checkbox"
                id={itemIsFree}
                value={itemIsFree}
                name={itemIsFree}
                checked={itemIsFree}
                onChange={handleCheckIsFree} // Truyền hàm xử lý sự kiện onChange từ component cha
              />
            </div>
          </div>
          {itemIsFree && (
            <InputText
              label="Item price"
              id="item"
              value={itemPrice}
              placeholder="Enter a item price"
              maxLength={10}
              onChange={(e) => setItemPrice(e.target.value)}
            />
          )}

          <div className={cx("form-group", "mb-0", "text-right")}>
            <Button className={cx("btn", "btn-primary")} as={Link} to="/admin/attributes">
              Back list Attribute
            </Button>
            {" "}
            <Button
              onClick={handleAddItem}
              className={cx("btn", "btn-primary")}
            >
              Add Item
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAttributesAddItem;
