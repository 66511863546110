import React from "react";
import classNames from "classnames/bind";
import styles from "layouts/DefaultLayout/DefaultLayout.module.scss";
import { Link } from "react-router-dom";
import useActiveLink from "hook/useLinkActive/useLinkActive";
import { SIGN_IN_PATH } from "constant/appPath";
import useUserInfo from "hook/useUserInfo";

const cx = classNames.bind(styles);

const MenuAccountLeft = () => {
  const name = "Xin chào, " + useUserInfo();
  const isAccountPageActive = useActiveLink("/account");
  const isAccountProductOfInterestPageActive = useActiveLink(
    "/account/product-of-interest"
  );
  const isAccountViewedProductPageActive = useActiveLink(
    "/account/viewed-product"
  );
  const isAccountMyOrderPageActive = useActiveLink("/account/my-order");
  const isAccountRewardPointsPageActive = useActiveLink(
    "/account/reward-points"
  );
  const isAccountMyVoucherPageActive = useActiveLink("/account/my-voucher");
  const isAccountIntroductoryDiagramPageActive = useActiveLink(
    "/account/introductory-diagram"
  );

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    window.location.href = SIGN_IN_PATH;
  };

  // const userToken = JSON.parse(localStorage.getItem("user_token"));
  return (
    <div className={cx("block-account")}>
      <h5 className={cx("title-account")}>Trang tài khoản</h5>
      <p>
        <span style={{ color: "#d82e4d" }}>{name}</span>
      </p>
      <ul>
        <li>
          <Link
            to="/account"
            className={cx("title-info", isAccountPageActive ? "active" : "")}
          >
            Thông tin tài khoản
          </Link>
        </li>
        <li>
          <Link
            to="/account/product-of-interest"
            className={cx(
              "title-info",
              isAccountProductOfInterestPageActive ? "active" : ""
            )}
          >
            Sản phẩm quan tâm
          </Link>
        </li>
        <li>
          <Link
            to="/account/viewed-product"
            className={cx(
              "title-info",
              isAccountViewedProductPageActive ? "active" : ""
            )}
          >
            Sản phẩm đã xem
          </Link>
        </li>
        <li>
          <Link
            to="/account/my-order"
            className={cx(
              "title-info",
              isAccountMyOrderPageActive ? "active" : ""
            )}
          >
            Đơn hàng của tôi
          </Link>
        </li>
        <li>
          <Link
            to="/account/reward-points"
            className={cx(
              "title-info",
              isAccountRewardPointsPageActive ? "active" : ""
            )}
          >
            Điểm thưởng
          </Link>
        </li>
        <li>
          <Link
            to="/account/my-voucher"
            className={cx(
              "title-info",
              isAccountMyVoucherPageActive ? "active" : ""
            )}
          >
            Voucher của tôi
          </Link>
        </li>
        <li>
          <Link
            to="/account/introductory-diagram"
            className={cx(
              "title-info",
              isAccountIntroductoryDiagramPageActive ? "active" : ""
            )}
          >
            Sơ đồ giới thiệu
          </Link>
        </li>
        <li>
          <Link
            className={cx("title-info")}
            onClick={handleLogout}
            style={{ color: "#d82e4d" }}
          >
            Đăng xuất
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MenuAccountLeft;
