import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "page/Application/Cart/Cart.module.scss";

const cx = classNames.bind(styles);

function PaymentTabs({ paymentMethods, onSelect }) {
    const [activeTab, setActiveTab] = useState(paymentMethods.length > 0 ? paymentMethods[0].id : null);
    const handleTabClick = (methodId) => {
        setActiveTab(methodId);
        onSelect(methodId);
    };

    return (
        <div className={cx("payment-tabs")}>
            {paymentMethods.map((method) => (
                <div
                    key={method.id}
                    className={cx("payment-tab", { active: activeTab === method.id })}
                    onClick={() => handleTabClick(method.id)}
                >
                    {method.name}
                </div>
            ))}
        </div>
    );
}

export default PaymentTabs;
