import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "page/Auth/Auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { login } from "api/auth/authen.api";
import { useRecoilState } from "recoil";
import { authenticationToken } from "recoil/atom/auth";
import { USER_PATH, ADMIN_PATH } from "constant/authPath";
import { SIGN_IN_PATH, SIGN_UP_PATH } from "constant/appPath";
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [, setToken] = useRecoilState(authenticationToken);
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toSignIn = SIGN_IN_PATH + SIGN_UP_PATH;

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    try {
      const response = await login(email, password);
      // Xử lý thành công
      setToken({
        accessToken: response.access_token,
        passwordExpiredRemainingDay: response.expires_in,
        name: response.user.last_name + " " + response.user.first_name,
        role: response.user.role,
      });
      if (response.user.role === "admin") {
        navigate(ADMIN_PATH);
      } else {
        navigate(USER_PATH);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      // Xử lý lỗi đăng nhập
      // setError(error.response.data.message);
    }
  };

  return (
    <Container className={cx("container-account")}>
      <Row className={cx("row-account")}>
        <Col>
          <h1 className={cx("account-text")}>Log in</h1>
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Label>Email</Form.Label>
            <FormGroup>
              <FormControl
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <br />
            <Form.Label>Password</Form.Label>
            <FormGroup className={cx("input-group")}>
              <FormControl
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputGroup.Text onClick={handleTogglePassword}>
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </InputGroup.Text>
            </FormGroup>
            <FormGroup>
              <Form.Label className={cx("row-to-sign-in")}>
                Need an account?{" "}
                <Link to={toSignIn} className={cx("text-sign-in")}>
                  Sign In
                </Link>
              </Form.Label>
              {/* <a href="#">Forgot password?</a> */}
            </FormGroup>
            <FormGroup>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
