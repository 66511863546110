import React from "react";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";

const cx = classNames.bind(styles);

const InputNumber = ({
  label,
  type = "number",
  id,
  name,
  value,
  placeholder,
  onChange,
  ...rest
}) => {
  return (
    <div className={cx("form-group", "row")}>
      <label htmlFor={id} className={cx("col-md-3", "col-form-label")}>
        {label}
      </label>
      <div className={cx("col-md-9")}>
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          step="0.01"
          className={cx("form-control")}
          {...rest}
        />
      </div>
    </div>
  );
};

export default InputNumber;
